import React, { useState, useEffect, useRef, useContext } from 'react';
import { Table, Modal, Button } from 'antd';
import TableFilter from 'utils/TableFilter';
import { instance } from 'utils/axios';
import { twMerge } from 'tailwind-merge';
import { Form } from 'components';
import fields from './fields';
import { FaPen } from 'react-icons/fa';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';
import { AuthContext } from 'contexts';

const Role = ({}) => {
  const [ data, setData ] = useState([])
  const [editData, setEditData] =useState(null)
  const [ loading, setLoading ] = useState(false)
  const [showModal, setShowModal] = useState(false);
  const [ searchedColumn, setSearchedColumn ] = useState('')
  const [ searchText, setSearchText ] = useState('')
  const searchInput = useRef(null)
  const [modal, contextHolder] = Modal.useModal();
  const [ form ] = Form.useForm();
  const { action } = useContext(AuthContext)

  useEffect(() => {
    callData()
    getOwnProfileData()
  }, [])

  const callData = () => {
    instance({
      method: 'get',
      url: '/role'
    }).then(res => {
      setData(res.data)
    }).catch(err => {
    }).then(() => {
      setLoading(false)
    })
  }
  
  const getOwnProfileData = () => {
    instance({
      method: 'get',
      url: '/userprofile'
    }).then(res => {
      // setData(res.data)
    }).catch(err => {
    }).then(() => {
    })
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('')
  };


  const handleSubmit = (values) => {
    setLoading(true);
    let keys = Object.keys(values);
    let datasend = {}
    keys.map((key) => {
      if(key === 'name'){
        datasend[key] = values[key] ? values[key] : ''; 
      }else{
        datasend[key] = values[key] ? 1 : 0; 
      }
    });
    if(editData){
      instance({
        method: 'put',
        url: `/role`,
        data: {
          ...datasend,
          id: editData.id,
        },
      }).then((res) => {
        callData();
        handleCancel();
      }).catch((err) => {
      }).finally(() => {
        setLoading(false);
      })
    }else{
      instance({
        method: 'post',
        url: `/role`,
        data: datasend,
      }).then((res) => {
        callData();
        handleCancel();
      }).catch((err) => {
      }).finally(() => {
        setLoading(false);
      })
    }
  };
  const handleAdd = () => {
    setShowModal(true);
  };

  const handleCancel = () => {
    setShowModal(false);
    if(editData){
      setEditData(null);
      form.resetFields();
    }else{
      form.resetFields();
    }
  };

  const handleEdit = (item) => {
    setEditData(item)
    setShowModal(true);
  };
  const columns = [
    {
      title: 'Хэрэглэгчийн эрх',
      name: 'name',
      dataIndex: 'name',
      key: 'name',
      ...TableFilter('name', 'Хэрэглэгчийн эрх', searchInput, handleSearch, handleReset, searchedColumn, searchText, searchInput),
    },
    {
      title: 'Хэрэглэгийн жагсаалт цэс',
      dataIndex: 'p_customer',
      key: 'p_customer',
      ...TableFilter('p_customer', 'Хэрэглэгийн жагсаалт цэс', searchInput, handleSearch, handleReset, searchedColumn, searchText, searchInput),
      render: (text, item) => {
        return(
            <span className={twMerge('font-semibold', text == 1 ? 'text-green-500' : 'text-red-500')}>
              {text == 1 ? <AiOutlineCheck size={16} /> : <AiOutlineClose />}
            </span>
          )
        }
    },
    {
      title: 'Баннер',
      dataIndex: 'p_advertise',
      key: 'p_advertise',
      ...TableFilter('p_advertise', 'Баннер', searchInput, handleSearch, handleReset, searchedColumn, searchText, searchInput),
      render: (text, item) => {
        return(
            <span className={twMerge('font-semibold', text == 1 ? 'text-green-500' : 'text-red-500')}>
              {text == 1 ? <AiOutlineCheck size={16} /> : <AiOutlineClose />}
            </span>
          )
        }
    },
    {
      title: 'Хянах самбар',
      dataIndex: 'p_dashboard',
      key: 'p_dashboard',
      ...TableFilter('p_dashboard', 'Хянах самбар', searchInput, handleSearch, handleReset, searchedColumn, searchText, searchInput),
      render: (text, item) => {
        return(
            <span className={twMerge('font-semibold', text == 1 ? 'text-green-500' : 'text-red-500')}>
              {text == 1 ? <AiOutlineCheck size={16} /> : <AiOutlineClose />}
            </span>
          )
        }
    },
    {
      title: 'Мэдээ засварлах',
      dataIndex: 'p_newsEdit',
      key: 'p_newsEdit',
      ...TableFilter('p_newsEdit', 'Мэдээ засварлах', searchInput, handleSearch, handleReset, searchedColumn, searchText, searchInput),
      render: (text, item) => {
        return(
            <span className={twMerge('font-semibold', text == 1 ? 'text-green-500' : 'text-red-500')}>
              {text == 1 ? <AiOutlineCheck size={16} /> : <AiOutlineClose />}
            </span>
          )
        }
    },
    {
      title: 'Нийтлэгч',
      dataIndex: 'p_newsPublisher',
      key: 'p_newsPublisher',
      ...TableFilter('p_newsPublisher', 'Нийтлэгч', searchInput, handleSearch, handleReset, searchedColumn, searchText, searchInput),
      render: (text, item) => {
        return(
            <span className={twMerge('font-semibold', text == 1 ? 'text-green-500' : 'text-red-500')}>
              {text == 1 ? <AiOutlineCheck size={16} /> : <AiOutlineClose />}
            </span>
          )
        }
    },
    {
      title: 'Тохиргоо цэс',
      dataIndex: 'p_settings',
      key: 'p_settings',
      ...TableFilter('p_settings', 'Тохиргоо цэс', searchInput, handleSearch, handleReset, searchedColumn, searchText, searchInput),
      render: (text, item) => {
        return(
            <span className={twMerge('font-semibold', text == 1 ? 'text-green-500' : 'text-red-500')}>
              {text == 1 ? <AiOutlineCheck size={16} /> : <AiOutlineClose />}
            </span>
          )
        }
    },
    {
      title: 'Системийн хэрэглэгч',
      dataIndex: 'p_systemUser',
      key: 'p_systemUser',
      ...TableFilter('p_systemUser', 'Системийн хэрэглэгч', searchInput, handleSearch, handleReset, searchedColumn, searchText, searchInput),
      render: (text, item) => {
        return(
            <span className={twMerge('font-semibold', text == 1 ? 'text-green-500' : 'text-red-500')}>
              {text == 1 ? <AiOutlineCheck size={16} /> : <AiOutlineClose />}
            </span>
          )
        }
    },
    {
      title: 'Видео мэдээ',
      dataIndex: 'p_videoNews',
      key: 'p_videoNews',
      ...TableFilter('p_videoNews', 'Видео мэдээ', searchInput, handleSearch, handleReset, searchedColumn, searchText, searchInput),
      render: (text, item) => {
        return(
            <span className={twMerge('font-semibold', text == 1 ? 'text-green-500' : 'text-red-500')}>
              {text == 1 ? <AiOutlineCheck size={16} /> : <AiOutlineClose />}
            </span>
          )
        }
    },
    {
      title: '',
      key: 'action',
      align: 'center',
      render: (text, item) => (
        <span className='flex gap-2'>
          <Button size='small' className='border-none bg-transparent hover:bg-transparent text-[#005594]' onClick={() => handleEdit(item)}><FaPen/></Button>
        </span>
      ),
    },
  ];

  return (
    <>
      <div className='flex justify-between bg-white py-2 px-3 border-b'>
        <span> Системийн хэрэглэгч <span>({data.length})</span></span>
        <Button type='primary' size='small' onClick={() => handleAdd()}>
          Нэмэх
        </Button>
      </div>
        <Table
          columns={columns}
          dataSource={data}
          rowKey={record => record.id}
          size='small'
          loading={loading}
          pagination={{
            defaultPageSize: 20
          }}
        >
      </Table>
      <Modal
        title={editData ? 'Засах' : 'Шинээр нэмэх'}
        open={showModal}
        onCancel={handleCancel}
        footer={[
          <Button key='cancel' onClick={handleCancel}>
            Цуцлах
          </Button>,
          <Button key='edit' type='primary' loading={loading} onClick={() => form.submit()}>
            Хадгалах
          </Button>,
        ]}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          fields={fields}
          editData={editData}
          className='gap-4' 
        />
      </Modal>
      {contextHolder}
    </>
  );
}

export default Role;
