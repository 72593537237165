import React from 'react';
import { Form, Input, Button } from 'antd';

const CompanyForm = ({}) => {
  return (
    <>
      <Form.Item
        name="regNo"
        rules={[
          {
            required: true
          }
        ]}>
        <Input size="large" className="py-4 rounded-xl" placeholder="Регистер"></Input>
      </Form.Item>
      <Form.Item
        name="firstName"
        rules={[
          {
            required: true
          }]
        }>
        <Input size="large" className="py-4 rounded-xl" placeholder="Оноосон нэр"></Input>
      </Form.Item>
      <Form.Item
        name="phoneNumber"
        rules={[
          {
            required: true
          }
        ]}>
        <Input size="large" className="py-4 rounded-xl" placeholder="Утас"></Input>
      </Form.Item>
      <Form.Item
        name="email"
        rules={[
          {
            required: true
          },
          {

          }
        ]}>
        <Input size="large" className="py-4 rounded-xl" type="email" placeholder="Имэйл"></Input>
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          {
            required: true
          }
        ]}>
        <Input size="large" className="py-4 rounded-xl" type="password" placeholder="Нууц үг"></Input>
      </Form.Item>
      <Form.Item
        name="repassword"
        dependencies={["password"]}
        hasFeedback
        rules={[
          {
            required: true
          },
          ({getFieldValue}) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('Нууц үг таарахгүй байна!'));
            },
          })
        ]}>
        <Input size="large" className="py-4 rounded-xl" type="password" placeholder="Нууц үг давтах"></Input>
      </Form.Item>
      <Form.Item>
        <Button className="w-full h-14 rounded-xl" size="large" type="primary" htmlType="submit">Бүртгүүлэх</Button>
      </Form.Item>
    </>
  );
}

export default CompanyForm;
