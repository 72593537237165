import { Button, Card, Skeleton, message, Input, Select } from "antd"
import { Form } from "components"
import { doc, getDoc, getFirestore, updateDoc } from "firebase/firestore"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { LeftOutlined } from '@ant-design/icons'
import { db, storage } from "utils/firebase"
import { ref, uploadBytes } from "firebase/storage"
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'

const TravelDetail = () => {
  const [ data, setData ] = useState(null)
  const [ loading, setLoading ] = useState(true)
  const [ editing, setEditing ] = useState(false)
  const [ form ] = Form.useForm()
  const { id } = useParams()
  // const db = getFirestore()
  const navigate = useNavigate()

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = () => {
    const docRef = doc(db, 'travels', id)
    getDoc(docRef).then(res => {
      console.log(res.data())
      setData(res.data())
    }).catch(err => {

    }).then(() => {
      setLoading(false)
    })
  }

  const handleLanguageChange = (e) => {
    if(data[e]) {
      form.setFieldsValue({
        ...data[e],
        ...data
      })
    }
    else {
      form.resetFields()
      // form.setFieldsValue({
      //   ...data,
      //   title: '',
      //   duration: '',
      //   content: '',
      //   pdf: null
      // })
      form.setFieldsValue({
        language: e,
        ...data,
      })
    }
  }

  const fields = [
    {
      type: "component",
      component: (
        <Form.Item className="col-span-12 mb-2" label="Хэл" name="language" rules={[{required: true, message: 'Төрөл заавал оруулана уу!'}]}>
          <Select onChange={handleLanguageChange}>
            <Select.Option value="mn">Монгол</Select.Option>
            <Select.Option value="en">Англи</Select.Option>
            <Select.Option value="kr">Солонгос</Select.Option>
          </Select>
        </Form.Item>
      )
    },
    {
      label: 'Төрөл',
      name: 'type',
      className: "col-span-12 mb-2",
      rules: [{required: true, message: 'Төрөл заавал оруулана уу!'}],
      type: "select",
      inputProps: {
        options: [
          {
            label: 'Гадаад',
            value: 'foreign'
          },
          {
            label: 'Дотоод',
            value: 'domestic'
          },
          {
            label: 'Гадаадуудад',
            value: 'forForeign'
          }
        ],
      },
    },
    {
      label: 'Категори',
      name: 'category',
      className: "col-span-12 mb-2",
      rules: [{required: true, message: 'Категори заавал оруулана уу!'}],
      type: "select",
      inputProps: {
        options: [
          {
            label: 'Travel',
            value: 0
          },
          {
            label: 'Day trips',
            value: 1
          },
          {
            label: 'City tour',
            value: 2
          },
          {
            label: 'Events',
            value: 3
          },
          {
            label: 'Sports Activities',
            value: 4
          },
        ],
      },
    },
    {
      label: "Гарчиг",
      name: "title",
      className: "col-span-12 mb-2",
      rules: [{required: true, message: 'Гарчиг заавал оруулана уу!'}],
      type: "text",
    },
    {
      label: "Хугацаа",
      name: "duration",
      className: "col-span-12 mb-2",
      rules: [{required: true, message: 'Хугацаа заавал оруулана уу!'}],
      type: "text",
    },
    {
      label: "Нийтлэл",
      name: "content",
      className: "col-span-12 mb-2",
      rules: [{required: true, message: 'Нийтлэл заавал оруулана уу!'}],
      type: "editor",
    },
    {
      label: "Аялсан хүний тоо",
      name: "traveledNumbers",
      className: "col-span-12 mb-2",
    },
    {
      label: "Файл",
      name: "image",
      type: "image",
      className: "col-span-12 mb-2",
      rules: [{required: true, message: 'Файл заавал оруулана уу!'}],
      inputProps: {
        accept: ".png, .jpeg, .jpg, .webp",
        maxCount: 1
      },
    },
    {
      label: "Зургийн цомог",
      name: "covers",
      type: "component",
      className: "col-span-12 mb-2",
      component: <Form.List name="covers" label="Ковер">
        {(fields, { add, remove}, { errors}) => (
          <>
          <label className="text-[#85878E]">Ковер</label>
          {
            fields.map(({key, name, ...restField}) => {
              return (
                <div key={key} className="col-span-12 flex items-baseline gap-2">
                  <Form.Item
                    name={[name]}
                    {...restField}>
                    <Input placeholder="Ковер линк">
                    
                    </Input>
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </div>
              )
            })
          }
          <Form.Item className="col-span-12">
            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
              Ковер нэмэх
            </Button>
          </Form.Item>
          </>
        )}
      </Form.List>
    },
    {
      label: "Танилцуулага файл",
      name: "pdf",
      type: "image",
      className: "col-span-12 mb-2",
      inputProps: {
        accept: ".pdf",
        maxCount: 1
      },
    },
  ]

  const handleSubmit = (values) => {
    setEditing(true)
    let promises = []
    if(typeof values.image == "object" && values.image) {
      promises.push(uploadBytes(ref(storage, `travels/${values.image.file.name}`), values.image.file))
    }
    if(typeof values.pdf == 'object' && values.pdf) {
      promises.push(uploadBytes(ref(storage, `travels/${values.language}/${values.pdf.file.name}`), values.pdf.file))
    }
    Promise.all([promises]).then(res => {
      updateDoc(doc(db, 'travels', id), {
        [values.language]: {
          title: values.title,
          duration: values.duration,
          content: values.content,
          pdf: values.pdf ? typeof values.pdf == "object" ? values.pdf.file.name : values.pdf : null
        },
        image: values.image ? typeof values.image == "object" ? values.image.file.name : values.image : null,
        type: values.type,
        covers: values.covers? values.covers : [],
        category: values.category
      }).then(() => {
        message.success("Амжилттай заслаа")
        navigate(-1)
      }).catch(err => {
        message.error("Засахад алдаа гарлаа")
      }).then(() => {
        setEditing(false)
      })
    }).catch(err => {
      console.log(err)
      message.error("Зураг хадгалахад алдаа гарлаа!")
    }).then(() => {
      setEditing(false)
    })
  }

  if(loading) {
    return (
      <div className="max-w-[1000px] min-w-[700px] mx-auto">
        <Skeleton>

        </Skeleton>
      </div>
    )
  }
  return (
    <div className="max-w-[1000px] min-w-[700px] mx-auto">
      <Button size='small' onClick={() => navigate('/travel')} className='flex items-center'><LeftOutlined/>Буцах</Button>
      <Card title="Аялал засах" className="mt-4">
        <Form
          form={form}
          fields={fields}
          onFinish={handleSubmit}
          editData={data}
          layout="vertical"
          className="mt-3 gap-x-4">
          <div className='col-span-12 flex justify-end gap-3 py-3'>
            <Button
              type='primary'
              onClick={form.submit}
              loading={editing}
            >
              Хадгалах
            </Button>

          </div>
        </Form>
      </Card>
    </div>
  )
}

export default TravelDetail