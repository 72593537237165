import { Editor } from '@tinymce/tinymce-react';
import React, { useEffect, useRef, useState } from 'react'
import ImageModal from './ImageModal';
import VideoModal from './VideoModal';
import { FaNewspaper } from 'react-icons/fa';
import { FiSettings } from 'react-icons/fi';
import peacock from 'assets/images/peacock.png'

function TinyEditor({form, name, height, width, editData, ...restProps}) {
  const [ showModal, setShowModal ] = useState(false)
  const [ showVideoModal, setShowVideoModal ] = useState(false)
  const editorRef = useRef(null)

  return (
    <>
      <Editor
        ref={editorRef}
        {...restProps}
        onInit={(e, editor) => editorRef.current = editor}
        apiKey='a22ry67l2lvwvfa9lvrr2yeeih0vpyw7brx1cc0g2lbm41rx'
        onChange={() => {}}
        init={{
          selector: "#editor",
          height: height ? height : '400px',
          width: width ? width :'100%',
          branding: false,
          toolbar: 'undo redo formatselect | bold italic forecolor quote | alignleft aligncenter alignright alignjustify lineheight bullist numlist outdent indent removeformat | videoButton menuImageButton bn_title peacock',
          content_css: false,
          content_style: `
            @import url('https://fonts.cdnfonts.com/css/sf-pro-display'); 
            body {
              font-family: 'SF Pro Display', sans-serif;
            }
            #quote-container {
              border-top: 6px solid #002f6c;
              border-bottom: 1px dotted #9b9b9b;
              padding-top: 15px;
              padding-bottom: 15px;
              margin-bottom: 16px;
            }
            blockquote {
              font-size: 20px;
              font-weight: 700;
              position: relative;
              margin-bottom: 20px;
              margin-top: 0px;
              text-align: left; 
            }
            blockquote::before {
              color: #2077b6;
              content: "“";
              font-size: 40px;
              left: -40px;
              top: 0;
              line-height: 1;
              font-family: serif;
              pointer-events: none;
              position: absolute;
            }
            #quote-footer {
              display: flex;
              gap: 15px;
              font-size: 11.2px;
              font-weight: 700;
              line-height: 16px;
              margin-left: 40px;
            }
            #quote-author {
              color: #005594;
            }
            #quote-author::before {
              content: "—";
              pointer-events: none;
              margin-right: 5px;
            }
            #author-dash {
              margin-right: 5px;
            }
            #quote-major {
              color: #747474;
              text-transform: uppercase;
              letter-spacing: 1px;
            }
          `,
          plugins: [
            'advlist', 'autolink', 'lists', 'link', 'image',
            'charmap', 'print', 'preview', 'anchor', 'help',
            'searchreplace', 'visualblocks', 'code','lineheight', 'textcolor',
            'insertdatetime', 'media', 'table', 'paste', 'wordcount', 'twitter'
          ],
          block_formats: 'Paragraph=p; Header 1=h2; Header 2=h3; Twitter=customtwitter',
          font_size_formats: "8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt",
          font_family_formats: "SF Pro Display=SF Pro Display, sans-serif;",
          formats: {
            customtwitter: { inline: 'span', styles: {backgroundColor: '#e9ebe4'}, classes: 'shareable', id: 'ww' }
          },
          style_formats: [
            { title: 'My PDF file', selector: 'a', classes: 'mypdffile' },
            { title: 'Twitter format', format: 'customtwitter'}
          ],
          file_picker_callback: function (callback, value, meta) {
            var input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/*');
            input.onchange = function () {
              var file = this.files[0];
              var reader = new FileReader();
              reader.onload = function (e) {
                callback(e.target.result, {
                  alt: file.name
              });

              };
              reader.readAsDataURL(file);
            };
            input.click();
          },
          setup: (editor) => {
            editor.ui.registry.addButton('peacock', {
              // icon: <FiSettings/>,
              text: 'peacock',
              onAction: (_) => {
                editor.insertContent(`
                  <span>
                    <img src="${process.env.REACT_APP_CDN_URL}/assets/news_thumbnail/75c618a5-d85f-4a2e-921f-e2cdc0941cac.png" style="height: 20px; width: auto; display: inline-block; vertical-align: baseline;"/>
                  </span>
                `)
              },
            });
            editor.ui.registry.addButton('quote', {
              icon: 'quote',
              onAction: (_) => {
                editor.insertContent(`
                  <div id="quote-container">
                    <blockquote>
                      Write quote
                    </blockquote>
                    <div id="quote-footer">
                      <div id="quote-author"> author</div>
                      <div id="quote-major">major</div>
                    </div>
                  </div>
                `)
              },
            });
            editor.ui.registry.addButton('bn_title', {
              icon: 'embed-page',
              onAction: (_) => {
                editor.insertContent("<p class=\"p1\" style=\"text-align: center;\"><em>Энэхүү мэдээлэл нь \"Шуурхай мэдээ\" бөгөөд илүү дэлгэрэнгүй мэдээллийг бид удахгүй та бүхэнд хүргэх болно.</em></p>")
              },
            });
            editor.ui.registry.addButton('videoButton', {
              icon: 'embed',
              onAction: (_) => setShowVideoModal(true),
            });
            editor.ui.registry.addMenuButton('menuImageButton', {
              icon: 'image',
              fetch: (callback) => {
                const items = [
                  {
                    type: 'menuitem',
                    text: 'Import image',
                    icon: 'image',
                    onAction: (_) => setShowModal(true),
                  },
                  // {
                  //   type: 'menuitem',
                  //   text: 'Import from gallery',
                  //   icon: 'gallery',
                  //   onAction: (_) => {
                  //   }
                  // },
                ];
                callback(items);
              }
            });
          },
        }}
        onEditorChange={(e, editor) => {
          form.setFieldValue(name, e)
        }}
      />
      <ImageModal 
        showModal={showModal} 
        handleCancel={() => setShowModal(false)}
        editorRef={editorRef}
        />
      <VideoModal 
        showModal={showVideoModal} 
        handleCancel={() => setShowVideoModal(false)}
        editorRef={editorRef}
      />
    </>
  )
}

export default TinyEditor