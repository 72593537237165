import React, { useContext } from 'react';
import { AuthContext } from 'contexts';
import { Navigate, useLocation } from 'react-router-dom';

const ProtectedRoute = ({ children, routeItem }) => {
  const {state} = useContext(AuthContext)
  const location = useLocation()
  const childrenWithProps = React.Children.map(children, child => {
    if(React.isValidElement(child)) {
        return React.cloneElement(child, {userInfo: state})
    }
    return child;
  })

  if(!state.token && !state.loading) {
    return (
      <Navigate to='/login' state={{from: location}} replace/>
    )
  }
  
  return childrenWithProps
}

export default ProtectedRoute;
