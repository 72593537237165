import React from 'react';
import { Header, Footer, Sidebar } from './components';
import { Outlet } from 'react-router-dom';

const Layout = ({children}) => {
  console.log('children', children);
  return (
    <div id="layout">
      <Sidebar></Sidebar>
      <main>
        <Header></Header>
        <div className='mt-14 px-5 pb-5 pt-3'>
          <Outlet/>
        </div>
      </main>
    </div>
  );
}

export default Layout;
