import { Button, Input, Select, message } from "antd";
import { Form } from "components";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import { instance } from "utils/axios";
import { CloseOutlined, MinusOutlined } from "@ant-design/icons";
import { ref, uploadBytes } from "firebase/storage";
import { addDoc, collection, getFirestore } from "firebase/firestore";
import { db, storage } from "utils/firebase";

function NewsAdd() {
  const [ categories, setCategories ] = useState([]);
  const [ subcategories, setSubCategories ] = useState([]);
  const [ journalists, setJournalists ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const [ saveLoading, setSaveLoading ] = useState(false)
  const [form] = Form.useForm();
  
  // const db = getFirestore()

  const navigate = useNavigate();

  useEffect(() => {
    form.setFieldValue("language", "mn")
  }, []);

  const fields = [
    {
      label: 'Хэл',
      name: 'language',
      className: "col-span-12 mb-2",
      rules: [{required: true, message: 'Төрөл заавал оруулана уу!'}],
      type: "select",
      inputProps: {
        options: [
          {
            label: 'Монгол',
            value: 'mn'
          },
          {
            label: 'Англи',
            value: 'en'
          },
          {
            label: 'Солонгос',
            value: 'kr'
          }
        ],
        defaultValue: 'mn'
      },
    },
    {
      label: "Гарчиг",
      name: "title",
      className: "col-span-12 mb-2",
      rules: [{required: true, message: 'Гарчиг заавал оруулана уу!'}],
      type: "text",
    },
    {
      label: 'Төрөл',
      name: 'type',
      className: "col-span-12 mb-2",
      rules: [{required: true, message: 'Төрөл заавал оруулана уу!'}],
    },
    {
      label: "Нийтлэл",
      name: "content",
      className: "col-span-12 mb-2",
      rules: [{required: true, message: 'Нийтлэл заавал оруулана уу!'}],
      type: "editor",
    },
    {
      label: "Зураг",
      name: "image",
      type: "image",
      className: "col-span-12 mb-2",
      rules: [{required: true, message: 'Зураг заавал оруулана уу!'}],
      inputProps: {
        accept: ".png, .jpeg, .jpg, .webp",
      },
    },
    {
      label: "Түргэн Холбоос",
      name: "link",
      className: "col-span-12 mb-2",
    },
  ];

  const handleSubmit = (status) => {
    setLoading(true)

    const values = form.getFieldsValue();
    const path = ref(storage, `news/${values.image.file.name}`)

    uploadBytes(path, values.image.file).then(res => {
      addDoc(collection(db, "news"), {
        [values.language]: {
          title: values.title,
          content: values.content,
        },
        image: values.image.file.name,
        link: values.link ? values.link : '',
        postDate: moment().format("YYYY-MM-DD HH:mm:ss"),
        status: status
      }).then(res => {
        message.success("Мэдээ амжилттай нэмлээ")
        navigate(-1)
      }).catch(err => {
        console.log(err)
        message.error("Мэдээ хадгалахад алдаа гарлаа!")
      })
    }).catch(err => {
      console.log(err)
      message.error("Зураг хадгалахад алдаа гарлаа!")
    })
    setLoading(false)
  };

  return (
    <div className="max-w-[1000px] min-w-[700px] mx-auto">
      <Button
        size="small"
        onClick={() => navigate("/news")}
        className="flex items-center"
      >
        <LeftOutlined />
        Буцах
      </Button>
      <div className="bg-white px-4 mt-4">
        <div className="py-3 border-b">Мэдээ нэмэх</div>
        <Form
          form={form}
          fields={fields}
          onFinish={handleSubmit}
          // closeModal={() => setShowModal(false)}
          // loading={loading}
          initValues={{
            categories: [{}]
          }}
          layout="vertical"
          className="mt-3 gap-x-4"
        >
          <div className="col-span-12 flex justify-end gap-3 py-3">
            <Button type="primary" onClick={() => handleSubmit(3)} loading={loading} disabled={saveLoading}>
              Нийтлэх
            </Button>
            <Button onClick={() => handleSubmit(0)} loading={saveLoading} disabled={loading}>Хадгалах</Button>
            <Button onClick={() => navigate("/editor/news")} disabled={loading || saveLoading}>Цуцлах</Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default NewsAdd;
