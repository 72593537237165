import { Button, Modal, Upload } from 'antd'
import { Form } from 'components'
import React, { useEffect, useState } from 'react'
import { instance } from 'utils/axios'

const fields = [
  {
    label: "Зураг",
    name: "image",
    type: "image",
    className: "col-span-12 mb-2",
    inputProps: {
      accept: ".png, .jpeg, .jpg, .webp",
    },
  },
  {
    label: "Зургийн тайлбар",
    name: "caption",
    className: "col-span-6 mb-2",
  },
  {
    label: "Зургийн эх үүсвэр",
    name: "source",
    className: "col-span-6 mb-2",
  },
]

function ImageModal({handleChange, showModal, handleCancel, editorRef }) {
  const [ loading, setLoading ] = useState()
  const [ form ] = Form.useForm()

  const handleSubmit = (values) => {
    setLoading(true)
    const formData = new FormData();
    formData.append("image", values.image?.file);
    instance({
      method: 'post',
      url: '/news/image',
      data: formData,
    }).then((res) => {
      editorRef?.current?.insertContent(`
        <figure style='border-bottom: 1px dotted;'>
          <img src='${process.env.REACT_APP_CDN_URL}${res.data}' class='content-img'/>
          ${(values.caption || values.source) ? 
            `
              <div class='image-caption-container' style='padding-top: 8px; padding-bottom: 15px; font-size: 12px;'>
                <div class='caption' style='font-weight: 600; margin-bottom: 5px;'>${values.caption}</div>
                <div class='source' style='text-transform: uppercase; font-style: italic; color: #747474;'>${values.source}</div>
              </div>
            `
            : null
          }
        </figure>
      `)
      handleCancel()
    }).catch((err) => {

    }).then(() => setLoading(false))
  }

  const handleChangeInput = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      // getBase64(info.file.originFileObj, (url) => {
      //   setLoading(false);
      //   // setImageUrl(url);
      // });
    }
  };

  const handleClick = (e) => {
    e.stopPropagation()
    form.submit()
  }

  return (
    <Modal
      title={'Import Image'}
      open={showModal}
      onCancel={handleCancel}
      destroyOnClose
      footer={[
        <Button key='cancel' onClick={handleCancel} disabled={loading}>
          Cancel
        </Button>,
        <Button key='edit' type='primary' loading={loading} onClick={handleClick}>
          Done
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        fields={fields}
        className='gap-4' 
      >
        {/* <Form.Item name='image' label='Image'>
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
            onChange={handleChangeInput}
          >
            {imageUrl ? (
              <img
                src={imageUrl}
                alt="avatar"
                style={{
                  width: '100%',
                }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item> */}
      </Form>
    </Modal>
  )
}

export default ImageModal