export default ({ role }) => {
  return  [
    {
      label: 'Хэрэглэгчийн нэр',
      name: 'username',
      rules: [{required: true, message:'Хэрэглэгчийн нэр оруулна уу!'}],
      className: 'col-span-6 mb-2',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'И-мэйл',
      name: 'email',
      rules: [{required: true, message:'И-мэйл оруулна уу!'}],
      className: 'col-span-6 mb-2',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Утас',
      name: 'phone',
      type: 'number',
      rules: [{required: true, message:'Утас оруулна уу!'}],
      className: 'col-span-6 mb-2',
      inputProps: {
        className: 'w-full',
      }
    },
    {
      label: 'Эрх',
      name: 'roleId',
      type: 'select',
      rules: [{required: true, message:'Эрх оруулна уу!'}],
      className: 'col-span-6 mb-2',
      inputProps: {
        options: role
      }
    },
    {
      label: 'Төлөв',
      name: 'isActive',
      type: 'select',
      rules: [{required: true, message:'Төлөв оруулна уу!'}],
      className: 'col-span-6 mb-2',
      inputProps: {
        options: [
          {label: 'Идэвхтэй', value: 1},
          {label: 'Идэвхгүй', value: 0},
        ],
      }
    },
    // {
    //   label: 'Зураг',
    //   name: 'file',
    //   type: 'file',
    //   rules: [{required: true, message:'Зураг оруулна уу!'}],
    //   className: 'col-span-12 mb-2',
    //   inputProps: {
    //   }
    // },
  ]
}