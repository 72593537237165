import { Image as AntImage } from 'antd'
import { getDownloadURL, ref } from 'firebase/storage'
import { useEffect, useState } from 'react'
import { storage } from 'utils/firebase'

const Image = ({src, base, ...restProps}) => {
  const [ mainSource, setMainSource ] = useState("")

  useEffect(() => {
    getImageUrl()
  }, [])

  
  const getImageUrl = async () => {
    const path = ref(storage, `${base}/${src}`)
    let _url = await getDownloadURL(path)
    setMainSource(_url)
  }
  console.log(mainSource)
  return (
    <AntImage
      src={mainSource}
      {...restProps}>

    </AntImage>
  )
}

export default Image