import React, { useContext, useEffect, useState } from 'react';
import { instance } from 'utils/axios';
import { FaBuilding, FaUserCog, FaUsers } from 'react-icons/fa';
import { BiSolidBaguette } from 'react-icons/bi';
import { FaBellConcierge, FaBuildingUser, FaIdCardClip } from 'react-icons/fa6';
import { Column } from '@ant-design/plots';
import { DatePicker, Skeleton, Tabs } from 'antd';
import { MainTable } from 'components';
import { AuthContext } from 'contexts';
import moment from 'moment';

const Dashboard = ({}) => {
  const [ data, setData ] = useState([]);
  const [ userData, setUserData ] = useState(null);
  const [ loading, setLoading ] = useState(false);
  const [ date, setDate ] = useState(null);
  const [ activeIndex, setActiveIndex ] = useState('0');
  const authContext = useContext(AuthContext);
  
  useEffect(() => {
    // fetchData();
  },[date]);
  
  const fetchData = () => {
    setLoading(true)
    if(authContext.state.userInfo.userinfo.role){
      instance({
        method: 'get',
        url: `/dashboard${date ? `?startDate=${date[0].format('YYYY-MM-DD')}&endDate=${date[1].format('YYYY-MM-DD')}` : ''}`
      }).then((res) => {
        setData(res.data);
      }).catch((err) => {
        
      }).then(() => setLoading(false))
    } else if(authContext.state.userInfo.userinfo.role === 'Тогооч'){
      instance({
        method: 'get',
        url: '/dashboard/cook'
      }).then((res) => {
        setUserData(res.data);
      }).catch((err) => {
      }).then(() => setLoading(false))
    }
  }

  const renderIcon = (name) => {
    switch (name) {
      case 'Системийн хэрэглэгчид': return <FaUserCog size={40} color='#00b4e8'/>
      case 'Салбар': return <FaBuilding size={40} color='#838388'/>
      case 'Хэлтэс, Газар': return <FaUsers size={40} color='#ff9b00'/>
      case 'Нийт хоол': return <FaBellConcierge size={40} color='#3bbe4e'/>
      case 'Харилцагчид': return <FaBuildingUser size={40} color='#ba41fe'/>
      case 'Ажилчид': return <FaIdCardClip size={40} color='#5849ff'/>
    }
  }

  const disabledDate = (current) => {
    return current > moment() || current < moment().subtract(30, 'day');
  }; 
  
  const config = {
    data: data?.sectorMonthLogData ? data?.sectorMonthLogData : [],
    xField: 'date',
    yField: 'value',
    seriesField: 'sector',
    isGroup: true,
    meta: {
      sector: {
        alias: 'Огноо',
      },
      sales: {
        alias: 'Нийт хоол',
      },
    },
  };

  const customerConfig = {
    data: data?.customerMonthLogData ? data?.customerMonthLogData : [],
    xField: 'date',
    yField: 'value',
    seriesField: 'customer',
    isGroup: true,
    meta: {
      sector: {
        alias: 'Огноо',
      },
      sales: {
        alias: 'Нийт хоол',
      },
    },
  };

  const columns = [
    {
      title: 'Барааны нэр',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Хэмжээ',
      dataIndex: 'allweight',
      key: 'allweight',
      render: (text, row, i) => (
        <span>{text}{row.uom}</span>
      )
    },
  ]

  return (
    <div className='px-5 bg-white py-3'>
      Засвартай
   </div>
  );
};

export default Dashboard;
