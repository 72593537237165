import React, { useEffect, useState } from 'react';
import { AuthContext } from 'contexts';
// import menu from '../../menu';
import { Menu } from 'antd';
import { IoChevronBack } from 'react-icons/io5';
import ls from 'utils/ls';
import logo from '../../../assets/images/logo.png';
import vLogo from '../../../assets/images/vLogo.png';
import { Link } from 'react-router-dom';
import { 
  BiUser,
  BiCategoryAlt,
  BiSolidCategoryAlt,
  BiNews,
  BiUserPin,
  BiVideo,
  BiPieChartAlt,
 } from 'react-icons/bi';
 import { FiSettings } from "react-icons/fi";
 import { RiAdvertisementLine, RiAdvertisementFill } from "react-icons/ri";
 import { PiUserCircleGear } from "react-icons/pi";
 import { HiOutlineUsers } from "react-icons/hi2";

const Sidebar = ({}) => {
  const {state, action} = React.useContext(AuthContext);
  const [ currentKey, setCurrentKey ] = useState('');

  function getItem({label, key, icon, children, type, permissionKey}) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const menu = [
    getItem({
      label: <Link to='/dashboard'>Хянах самбар</Link>,
      icon: <BiPieChartAlt size={16} className="inline text-[#005594]" />,
      key: 'dahboard',
      
    }),
    getItem({
      label: <Link to="/news">Мэдээ</Link>,
      icon: <BiNews size={16} className="inline text-[#005594]" />,
      key: "News",
      
    }),
    getItem({
      label: <Link to="/banner">Banner</Link>,
      icon: <RiAdvertisementLine size={16} className="inline text-[#005594]" />,
      key: "Banner",
      permissionKey: "p_advertise",
    }),
    getItem({
      label: <Link to="/travel">Аялал</Link>,
      icon: <HiOutlineUsers size={16} className="inline text-[#005594]" />,
      key: "Travel",
    }),   
    getItem({
      label: <Link to="/users">Хэрэглэгийн жагсаалт</Link>,
      icon: <HiOutlineUsers size={16} className="inline text-[#005594]" />,
      key: "Users",
    }), 
    getItem({
      label: <Link to="/systemuser">Системийн хэрэглэгч</Link>,
      icon: <BiUserPin size={16} className="inline text-[#005594]" />,
      key: "SystemUsers",
      
    }),
    // getItem({
    //   label: 'Тохиргоо',
    //   icon: <FiSettings size={16} className="inline text-[#005594]" />,
    //   key: "Settings",
      
    //   children: [
    //     getItem({
    //       label: <Link to="/roles">Эрхийн тохиргоо</Link>,
    //       icon: <PiUserCircleGear size={18} className="inline text-[#005594]" />,
    //       key: "Role",
          
    //     }),
    //     getItem({
    //       label: <Link to="/category">Үндсэн ангилал</Link>,
    //       icon: <BiCategoryAlt size={16} className="inline text-[#005594]" />,
    //       key: "MainCategory",
          
    //     }),
    //     getItem({
    //       label: <Link to="/bannertype">Banner Type</Link>,
    //       icon: <RiAdvertisementFill size={16} className="inline text-[#005594]" />,
    //       key: "BannerType",
          
    //     }),
    //     getItem({
    //       label: <Link to="/homecategories">Home Categories</Link>,
    //       icon: <BiSolidCategoryAlt size={16} className="inline text-[#005594]" />,
    //       key: "HomeCategories",
          
    //     }),
    //   ]
    // }),
  ];

  const handleClickMenu = (e) => {
    setCurrentKey(e.key);
    ls.set('currentMenuKey', e.key);
  };

  const handleChangeCollapse = () => {
    action.handleSidebarCollapse(!state.sidebarCollapsed);
  };
  
  return (
      <div className='sidebar w-full'>
        <div className='flex justify-center py-2 px-4 border-b'>
          {state.sidebarCollapsed ? 
            <img src={vLogo} alt="logo" height={24} className='w-auto' />
            :
            <img src={logo} alt="logo" height={24} className='w-auto' />
          }
        </div>
        <div className=' overflow-auto'>
          <Menu
            defaultSelectedKeys={['menu-item-0']}
            mode={'inline'}
            theme={'light'}
            selectedKeys={[currentKey]}
            onClick={handleClickMenu}
            className='text-base max-h-[calc(100vh-50px-36px)]'
            key={'menu'}
            inlineCollapsed={state.sidebarCollapsed}
            items={menu}
          />
        </div>
        {/* <div className={'flex justify-end absolute bottom-0 right-0 left-0 border-t'}>
          <button className='px-4 py-2 bg-transparent cursor-pointer' onClick={handleChangeCollapse}>
            <IoChevronBack size={20} className={`transition-all duration-500 ${state.sidebarCollapsed ? 'rotate-180' : 'rotate-0'}`}/>
          </button>
        </div> */}
      </div>
  );
};

export default Sidebar;
