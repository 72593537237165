import {Button, Input, Select } from 'antd';
import { Form } from 'components';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons'
import { instance } from 'utils/axios';

const Active = [
  {label: <div className='flex items-center gap-2'>Идэвхгүй</div>, value: 0},
  {label: <div className='flex items-center gap-2'>Идэвхтэй</div>, value: 1}, 
]

function SubCategory_Edit() {
  const [ editData, setEditData ] = useState(null)
  const [ data, setData ] = useState(null)

  const [ loading, setLoading ] = useState(false)
  const [ form ] = Form.useForm()

  const navigate = useNavigate()
  const { newsId } = useParams()

  useEffect(() => {
    if(data){
      setEditData({
        ...data,
        name: data?.name,
            })
    }
  },[data])

  useEffect(() => {
    getData()
  },[])

  const getData = () => {
    instance({
      method: 'get',
      url: `/system_users/${newsId}`
    }).then(res => {
      setData(res.data);
    }).catch(err => {
    }).then(() => {
      setLoading(false)
    })
  }


  const fields = [
    {
      label: 'Нэр',
      name: 'name',
      className: 'col-span-12 mb-2',
      type: 'text',
    },
      
    {
        label: 'Email',
        name: 'email',
        className: 'col-span-12 mb-2',
        type: 'text',
        rules: [
          {
            type: 'email',
            message: 'Email хаяг оруулна уу',
          },
          {
            message: 'Email шаардлагатай',
          },
        ],
      },
       {
      label: 'Төлөв',
      name: 'isActive',
      className: 'col-span-6 mb-2',
      type: 'select',
      inputProps: {
        options: Active,
      },
      initialValue: editData ? editData.isActive : undefined,
    },
  ];
  const handleSubmit = (values) => {
    instance({
      method: 'put',
      url: `/system_users`,
      data: {
        id: newsId,
        ...values,
      },
    }).then((res) => {

    }).catch((err) => {

    }).finally(() => {
      navigate('/editor/subcategory')

    })
  }
  return (
    <div className='max-w-[1000px] min-w-[700px] mx-auto'>
      <Button size='small' onClick={() => navigate('/editor/category')} className='flex items-center'><LeftOutlined/>Буцах</Button>
      <div className='bg-white px-4 mt-4'>
        <div className='py-3 border-b font-semibold'>хэрэглэгч засах</div>
        <Form
          form={form}
          fields={fields}
          onFinish={handleSubmit}
          editData={data}
          layout='vertical'
          className='mt-3 gap-x-4'
        >
          <div className='col-span-12 flex justify-end gap-3 py-3'>
            <Button
              type='primary'
              onClick={form.submit}
            >
              Хадгалах
            </Button>

          </div>
        </Form>
      </div>
    </div>
  )
}
export default SubCategory_Edit