import React, { useState, useContext, useEffect } from 'react';
import { Button, Modal, Form as AntForm } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from 'contexts';
import { Form } from 'components';
import { instance } from 'utils/axios';
import ls from 'utils/ls';
import { MenuOutlined, CloseOutlined } from '@ant-design/icons'
import moment from 'moment';
import { twJoin, twMerge } from 'tailwind-merge';

const Header = () => {
  const [ dropdownOpen, setDropdownOpen ] = useState(false);
  const [ openModal, setOpenModal ] = useState(false);
  const [ openStatusModal, setOpenStatusModal ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ isOpenMenu, setIsOpenMenu ] = useState(false);
  const { state, action } = React.useContext(AuthContext);
  const [ form ] = AntForm.useForm();
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  var [ date, setDate ] = useState(moment().format('YYYY/MM/DD HH:mm:ss'));

  useEffect(() => {
    if(authContext.state.userInfo?.dateMenuStatus === false){
      setOpenStatusModal(true);
    };

  }, [])

  const handleLogout = () => {
    authContext.action.logout();
    ls.remove('userInfo') ;
    navigate("/login");
  };

  const fields = [
    {
      label: 'Хуучин нууц үг',
      name: 'oldpassword',
      type: 'password',
      className: 'col-span-12 mb-0',
      rules: [{required: true, message: 'Хуучин нууц үг оруулна уу'}]
    },
    {
      label: 'Шинэ нууц үг',
      name: 'newpassword',
      type: 'password',
      className: 'col-span-12 mb-0',
      rules: [{required: true, message: 'Шинэ нууц үг оруулна уу'}]
    },
    {
      label: 'Шинэ нууц үг давтах',
      name: 'confirmpassword',
      type: 'password',
      className: 'col-span-12 mb-0',
      rules: [{required: true, message: 'Шинэ нууц үг давтан оруулна уу'}]
    },
  ];

  const handleSubmitChangePassword = (values) => {
    setLoading(true)
    instance({
      method: 'put',
      url: 'changepassword',
      data: {
        oldPassword: values.oldpassword,
        password: values.newpassword,
      }
    }).then(() => {
      setOpenModal(false)
    }).catch((err) => {
    }).then(() => setLoading(false)) 
  };

  const handleCloseModal = () => {
    setOpenModal(false)
    form.resetFields()
  };
  const handleCloseStatusModal = () => {
    setOpenStatusModal(false);
  };
  const handleReq = () =>{
    navigate("/editor/sectordatemenu");
    setOpenStatusModal(false);
  };
  
  return (
    <>
      <header className={twMerge("fixed w-[500px] z-40 border-l", state.sidebarCollapsed ? 'w-[calc(100vw-75px)]' : 'w-[calc(100vw-250px)]')}>
        <div className=' flex flex-row justify-end bg-white shadow-lg'>
          <div className="px-10 py-2 relative flex items-end justify-center gap-4" onMouseOver={() => setDropdownOpen(true)} onMouseLeave={() => setDropdownOpen(false)}>
            <div className='text-right'>
              <div className='text-black text-base'>
                {authContext.state.userInfo?.email} 
                 {/* {authContext.state.userInfo?.userinfo.sectorName && `/ ${authContext.state.userInfo?.userinfo.sectorName}`} */}
              </div>
              {/* <div className='text-mute text-xs'>{authContext.state.userInfo?.userinfo.role} {date}</div> */}
            </div>
            {/* {
              authContext.state.userInfo?.userinfo.role === 'Тогооч' ? 
              <img src={cook} width={32} alt='cook'/> :
              authContext.state.userInfo?.userinfo.role === 'Админ' ? 
              <img src={admin} width={32} alt='admin'/> : 
              authContext.state.userInfo?.userinfo.role === 'Санхүү' ? 
              <BiCalculator className="text-3xl"></BiCalculator> : 
              <AiOutlineUser className="text-3xl"></AiOutlineUser>
            } */}
            {
              dropdownOpen &&
              <div className="flex flex-col absolute right-0 bottom-0 py-2 rounded z-20 bg-white shadow-xl translate-y-full whitespace-nowrap" tabIndex={-1}>
                <div className="text-graytext p-4 hover:text-primary transition-all border-b last:border-b-0" onClick={() => setOpenModal(true)}>Нууц үг солих</div>
                <div className="text-graytext p-4 hover:text-primary transition-all border-b last:border-b-0" onClick={handleLogout}>Гарах</div>
              </div>
            }
          </div>
          <button
            onClick={() => setIsOpenMenu(!isOpenMenu)} 
            className='cursor-pointer text-white bg-transparent outline-none block md:hidden'
          >
            {
              isOpenMenu ? 
              <CloseOutlined color='white' />
              :
              <MenuOutlined color='white' />
            }
          </button>
        </div>
      </header>
      <Modal
        title={<div className='text-lg'>Нууц үг солих</div>}
        open={openModal} 
        onCancel={handleCloseModal}
        width={700}
        destroyOnClose  
        footer={false}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmitChangePassword}
          initialValues={[]}
          fields={fields}
          className='gap-4'
        />
        <div className='flex justify-end mt-4'>
          <Button type='primary' onClick={() => form.submit()} loading={loading}>Хадгалах</Button>
        </div>
      </Modal>
      <Modal
        open={openStatusModal}
        onCancel={handleCloseStatusModal}
        destroyOnClose
        closable={false}
        footer={false}
      >
        <div className='flex flex-col justify-center gap-8  items-center'>
          <div className='text-xl'>{authContext.state.userInfo?.dateMenuStatus === false && 'Өнөөдрийн хоол бүртгээгүй байна!'}</div>
          <Button className='w-full' onClick={handleReq} type='primary'>Бүртгэх</Button>
        </div>
      </Modal>
    </>
  );
};

export default Header;
