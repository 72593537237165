import React, { useState, useEffect, useRef } from 'react';
import { Table, Modal, Tag, Progress, Badge, Switch, Button } from 'antd';
import axios from 'axios';
import TableFilter from 'utils/TableFilter';
import { Form } from 'components';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { instance } from 'utils/axios';
import { twMerge } from 'tailwind-merge';
import { FaPen, FaTrash } from 'react-icons/fa';


const BannerType = ({}) => {
  const [ data, setData ] = useState([])
  const [ loading, setLoading ] = useState(false)
  const [ searchedColumn, setSearchedColumn ] = useState('')
  const [ searchText, setSearchText ] = useState('')
  const [ showModal, setShowModal ] = useState(false)
  const [ editData, setEditData ] = useState(null)
  const searchInput = useRef(null)
  const [ form ] = Form.useForm()
  const [modal, contextHolder] = Modal.useModal();


  useEffect(() => {
    callData()
  }, [])

  const callData = () => {
    setLoading(true)
    instance({
      method: 'get',
      url: '/b_type'
    }).then(res => {
      setData(res.data)
    }).catch(err => {
    }).then(() => {
      setLoading(false)
    })
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('')
  };

  const handleRowDeleteClick = (data, e) => {
    e.stopPropagation()
    modal.confirm({
      title: 'Анхаарна уу?',
      content: 'Энэхүү мөр бичлэгийг устгах гэж байна',
      okText: 'Устгах',
      cancelText: 'Буцах',
      onOk() {
        return new Promise((resolve, reject) => {
          instance({
            method: 'delete',
            url: `b_type/${data.id}`
          }).then(res => {
            resolve()
          }).catch(err => {
            reject()
          }).then(() => {
            callData()
          })
        }).catch(() => {});
      },
      onCancel() {},
    });
  }

  const handleEditClick = (row) => {
    setEditData(row)
    setShowModal(true)
  }

  const columns = [
    {
      title: 'Төрөл',
      dataIndex: 'adType',
      key: 'adType',
      ...TableFilter('adType', 'категори', searchInput, handleSearch, handleReset, searchedColumn, searchText, searchInput),
    },
    {
      title: 'Хэмжээ',
      dataIndex: 'size',
      key: 'size',
      ...TableFilter('size', 'Хэмжээ', searchInput, handleSearch, handleReset, searchedColumn, searchText, searchInput),
    },
    {
      title: 'Үнэ',
      dataIndex: 'price',
      key: 'price',
      ...TableFilter('price', 'Үнэ', searchInput, handleSearch, handleReset, searchedColumn, searchText, searchInput),
      render: (text, item) => {
        return(
          <span>{Intl.NumberFormat().format(text)} ₮</span>
        )
      }
    },
    {
      title: '',
      key: 'action',
      align: 'center',
      width: 120,
      render: (text, item) => (
        <span className='flex gap-2'>
          <Button size='small' className='border-none bg-transparent hover:bg-transparent text-[#005594]' onClick={(e) => handleEditClick(item, e)}><FaPen/></Button>
          {/* <Button danger size='small' onClick={(e) => handleRowDeleteClick(item, e)}><FaTrash/></Button> */}
        </span>
      ),
    },
  ];

  const fields = [
    // {
    //   label: 'зарын төрөл',
    //   name: 'adType',
    //   className: 'col-span-12 mb-2',
    // },
    // {
    //   label: 'Хэмжээ',
    //   name: 'size',
    //   className: 'col-span-12 mb-2',
    // },
    {
      label: 'Үнэ',
      name: 'price',
      className: 'col-span-12 mb-2',
      type: 'price',
    },
  ];

  const handleSubmit = (values) => {
    console.log('values', values);
    if(editData){
      instance({
        method: 'put',
        url: '/b_type',
        data: {
          ...values,
          id: editData.id
        }
      }).then((res) => {
        handleCancel()
        callData()
      }).catch((err) => {
  
      }).finally(() => {
  
      })
    }else{
      instance({
        method: 'post',
        url: '/b_type',
        data: {
          ...values
        }
      }).then((res) => {
        handleCancel()
        callData()
      }).catch((err) => {
  
      }).finally(() => {
  
      })
    }
  }

  const handleCancel = () => {
    setShowModal(false)
    form.resetFields()
  }

  const handleAddClick = () => {
    setEditData(null)
    setShowModal(true)
  }

  return (
    <>
      <div className='flex justify-between bg-white py-2 px-3 border-b'>
        <span> Мэдээний жагсаалт <span>({data.length})</span></span>
        {/* <Button type='primary' size='small' onClick={handleAddClick}>
          Нэмэх
        </Button> */}
      </div>
      <Table
        columns={columns}
        dataSource={data}
        rowKey={record => record.id}
        size='small'
        loading={loading}
        pagination={{
          defaultPageSize: 20
        }}
      />
      <Modal
        title={editData ? `${editData.adType} Засах` : 'Шинээр нэмэх'}
        open={showModal}
        onCancel={handleCancel}
        footer={[
          <Button key='cancel' onClick={handleCancel}>
            Цуцлах
          </Button>,
          <Button key='edit' type='primary' loading={loading} onClick={() => form.submit()}>
            Хадгалах
          </Button>,
        ]}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          fields={fields}
          editData={editData}
          className='gap-4' 
        />
      </Modal>
      {contextHolder}
    </>
  );
}

export default BannerType;
