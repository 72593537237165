import { Button, Modal, Upload } from 'antd'
import { Form } from 'components'
import React, { useEffect, useState } from 'react'
import { instance } from 'utils/axios'

const fields = [
  {
    label: "Video URL",
    name: "url",
    className: "col-span-12 mb-2",
  },
]

function VideoModal({handleChange, showModal, handleCancel, editorRef }) {
  const [ loading, setLoading ] = useState()
  const [ form ] = Form.useForm()

  const handleSubmit = (values) => {
    const params = new URL(values.url)
    console.log('params', params.searchParams.get('v'));
    const videoId = params.searchParams.get('v')
    
    if(params){
      editorRef?.current?.insertContent(`
        <iframe width="100%" height="auto" style="aspect-ratio: 16 / 9;" class="content-video" frameborder="0" allowfullscreen
        src="https://youtube.com/embed/${videoId}">
        </iframe>
      `)
      handleCancel()
    }
  }


  const handleChangeInput = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      // getBase64(info.file.originFileObj, (url) => {
      //   setLoading(false);
      //   // setImageUrl(url);
      // });
    }
  };

  const handleClick = (e) => {
    e.stopPropagation()
    form.submit()
  }

  return (
    <Modal
      title={'Import Image'}
      open={showModal}
      onCancel={handleCancel}
      destroyOnClose
      footer={[
        <Button key='cancel' onClick={handleCancel} disabled={loading}>
          Cancel
        </Button>,
        <Button key='edit' type='primary' loading={loading} onClick={handleClick}>
          Done
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        fields={fields}
        className='gap-4' 
      >
        {/* <Form.Item name='image' label='Image'>
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
            onChange={handleChangeInput}
          >
            {imageUrl ? (
              <img
                src={imageUrl}
                alt="avatar"
                style={{
                  width: '100%',
                }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item> */}
      </Form>
    </Modal>
  )
}

export default VideoModal