import React, { useState, useEffect } from 'react';
import { Input, Form, Button, message } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from 'contexts';
import { instance } from 'utils/axios';
import bgImage from 'assets/images/background.png';
import logo from 'assets/images/logo.png';
import { ReactComponent as Logo } from 'assets/images/logo-white.svg';
import moment from 'moment';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import {  signInWithEmailAndPassword   } from 'firebase/auth';
import { auth } from 'utils/firebase';

const Login = () => {
  const [ loading, setLoading ] = useState(false)
  const [ isReset, setIsReset ] = useState(false)
  const authContext = React.useContext(AuthContext)
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate()
  const [ form ] = Form.useForm()

  useEffect(() => {
    if(authContext.state.token) {
      navigate("/dashboard", {replace: true});
    }
  }, [authContext.state])

  const handleSubmit = async ({email, password}) => {
    setLoading(true)
    await signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            authContext.action.login(user.uid, user)
            // navigate("/login")
            // ...
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            messageApi.open({
              type: 'error',
              content: 'Нэвтрэх нэр эсвэл нууц үг буруу байна.'
            })
            console.log(errorCode, errorMessage);
            // ..
        })

    setLoading(false)
  }

  const handleSubmitReset = (values) => {
    setLoading(true)
    instance({
      method: 'post',
      url: 'auth/resetpassword',
      data: {
        ...values
      }
    }).then((res) => {
      setIsReset(!isReset)
    }).catch((err) => {
      
    }).then(() => setLoading(false))
  }

  const handleFormChange = () => {
    form.setFields([
      {
        name: 'email',
        errors: []
      },
      {
        name: 'password',
        errors: []
      }
    ])
  }

  return (
    <div className='flex h-screen bg-[#001c3e] justify-center items-center'>
      <div className="flex flex-col p-4 px-6 min-w-[400px] border border-primary rounded-xl shadow-2xl login-card">
        <div className="py-10 w-full">
          <div className="">
            <div className='flex flex-col items-center'>
              <img src={logo} width={200} alt="logo" />
              
              <div className='border-t border-b-2 border-gray-200 w-[150px] mt-2'></div>
              <div className='text-white text-lg font-bold'>Mongolia</div>
            </div>
            {
              !isReset ? 
              <Form
                form={form}
                className="md:mt-10"
                onFinish={handleSubmit}
                onValuesChange={handleFormChange}
                layout='vertical'
              >
                <Form.Item
                  name="email"
                  rules={[{required: true, message: 'Нэвтрэх нэр оруулна уу!'}]}
                >
                  <Input 
                    size="middle"
                    prefix={<UserOutlined />}
                    placeholder='Нэвтрэх нэр' 
                    className="py-3 rounded-lg"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[{required: true, message: 'Нууц үг код оруулна уу!'}]}
                >
                  <Input.Password
                    size="middle" 
                    prefix={<LockOutlined />}
                    type="password" 
                    placeholder='Нууц үг' 
                    className="py-3 rounded-lg"
                  />
                </Form.Item>
                <Form.Item>
                  <div className="flex justify-end text-white">
                    <Link className="underline hover:text-blue-300" onClick={() => setIsReset(!isReset)}>
                      Нууц үг сэргээх
                    </Link>
                  </div>
                </Form.Item>
                <Form.Item>
                  <Button 
                    loading={loading} 
                    disabled={loading} 
                    block 
                    type="primary" 
                    size="middle"
                    htmlType="submit" 
                    className="bg-primary border-none h-11 rounded-lg"
                  >
                    Нэвтрэх
                  </Button>
                </Form.Item>
              </Form>
              :
              <>
                <div className='text-2xl text-center text-primary  mt-5 font-bold'>Нууц үг сэргээх</div>
                <Form
                  form={form}
                  className="md:mt-8"
                  onFinish={handleSubmitReset}
                  layout='vertical'
                >
                  <Form.Item
                    label='И-Мэйл хаяг'
                    name="email"
                    className='mb-3'
                    rules={[{required: true, message: 'И-Мэйл хаяг оруулна уу!'}]}
                  >
                    <Input 
                      size="middle" 
                      placeholder='И-Мэйл хаяг' 
                      className="py-3 rounded-lg"
                    />
                  </Form.Item>
                  <div className='mb-4 text-xs text-gray-500 p-3 border border-orange-200 rounded-lg bg-orange-100'>Та зөвхөн өөрийн бүртгэлтэй и-мэйл хаягийг оруулснаар тухайн и-мэйл хаягт шинэ нууц үг илгээх болно</div>
                  <Form.Item>
                    <div className="flex justify-end">
                      <Link className="underline text-white hover:text-blue-300" onClick={() => setIsReset(!isReset)}>
                        Нэвтрэх хэсэг рүү буцах
                      </Link>
                    </div>
                  </Form.Item>
                  <Form.Item>
                    <Button 
                      loading={loading} 
                      disabled={loading} 
                      block 
                      type="primary" 
                      size="middle" 
                      htmlType="submit" 
                      className="bg-primary border-none hover:bg-blue-600 h-11 rounded-lg"
                    >
                      Илгээх
                    </Button>
                  </Form.Item>
                </Form>
              </>
            }
          </div>
        </div>
      </div>
      {contextHolder}
    </div>
  );
}

export default Login;
