  const reducer = (prevState, action) => {
    switch (action.type) {
      case 'LOGIN':
        return {
          ...prevState,
          token: action.token,
          userInfo: action.userInfo
        }
      case 'LOGOUT':
        return {
          ...prevState,
          token: null,
          userInfo: null
        }
      case 'CHANGE_TAB_INDEX_SETTING':
        return {
          ...prevState,
          tabIndexSetting: action.index,
        }
      case 'CHANGE_TAB_INDEX_SETTING':
        return {
          ...prevState,
          tabIndexSetting: action.index,
        }
      case 'CHANGE_SIDEBAR_WIDTH':
        return {
          ...prevState,
          sidebarWidth: action.sidebarWidth,
          sidebarCollapsed: action.sidebarCollapsed
        }
      case 'RESTORE_TOKEN':
        return {
          ...prevState,
          token: action.token,
          userInfo: action.userInfo,
          loading: false
        }
      default:
        return prevState;
    }
  }

  export default reducer
