import React from 'react';
import moment from 'moment';
import { ConfigProvider, message } from 'antd';
import { HashRouter, RouterProvider } from 'react-router-dom';
import { AuthProvider } from 'contexts';
import AxiosProvider from 'utils/axios';
import mnMN from 'antd/lib/locale/mn_MN';
import router from 'routes';
import StyleConfigProvider from 'StyleConfigProvider';


moment.updateLocale('mn', {
  week: {
    dow: 1
  },
  weekdays: ['Ням', 'Даваа', 'Мягмар', 'Лхагва', 'Пүрэв', 'Баасан', 'Бямба'],
  weekdaysShort: ['Ням', 'Дав', 'Мяг', 'Лха', 'Пүр', 'Баа', 'Бям'],
  weekdaysMin: ['Ня', 'Да', 'Мя', 'Лх', 'Пү', 'Ба', 'Бя'],
  months: ['1-р сар', '2-р сар', '3-р сар', '4-р сар', '5-р сар', '6-р сар', '7-р сар', '8-р сар', '9-р сар', '10-р сар', '11-р сар', '12-р сар'],
  relativeTime : {
      future: "%s-ийн дараа",
      past:   "%s өмнө",
      s  : 'секундын',
      ss : '%d секунд',
      m:  "минутын",
      mm: "%d-н минутын өмнө",
      h:  "цагийн",
      hh: "%d цагийн өмнө",
      d:  "өчигдөр",
      dd: "%d өдрийн өмнө",
      w:  "7 хоногийн өмнө",
      ww: "%d 7 хоногийн өмнө",
      M:  "сарын өмнө",
      MM: "%d сарын өмнө",
      y:  "жилийн өмнө",
      yy: "%d жилийн өмнө"
  },
  calendar : {
      lastDay : '[Өчигдөр]',
      sameDay : '[Өнөөдөр]',
      nextDay : '[Маргааш]',
      lastWeek : 'dddd',
      nextWeek : 'dddd',
      sameElse : 'L'
  }
})

message.config({
  prefixCls: 'power-message',
})

function App() {
  return (
    <React.Suspense fallback={<div>Loading</div>}>
      <ConfigProvider locale={mnMN} prefixCls='power'>
        <AuthProvider>
          <AxiosProvider>
            <RouterProvider router={router}>
            </RouterProvider>
          </AxiosProvider>
        </AuthProvider>
      </ConfigProvider>
    </React.Suspense>
  );
}

export default App;
