import {Button, Input, Select, Spin, message } from 'antd';
import { Form } from 'components';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons'
import { IoCloseOutline } from 'react-icons/io5';
import { Option } from 'antd/lib/mentions';
import { instance } from 'utils/axios';
import { ref, uploadBytes } from 'firebase/storage';
import { db, storage } from 'utils/firebase';
import { addDoc, collection, getFirestore } from 'firebase/firestore';

const Active = [
  {label: <div className='flex items-center gap-2'>Идэвхгүй</div>, value: 0},
  {label: <div className='flex items-center gap-2'>Идэвхтэй</div>, value: 1}, 
]

function Banner_Add() {
  const [ loading, setLoading ] = useState(false)
  const [ form ] = Form.useForm()
  const [ ad_type, setAd_type ] = useState([])
  const [ size, setSize ] = useState([])
  const [ data, setData ] = useState([])
  const navigate = useNavigate()
  // const db = getFirestore()

  useEffect(() => {

  },[])

  const fields = [
    {
      label: 'Зарын төрөл',
      name: 'ad_typeId',
      className: 'col-span-6 mb-2',
      type: 'select',
      rules: [{required: true, message: 'Зарын төрөл сонгоно уу!'}],
      inputProps: {
        options: [
          {
            label: 'Утас',
            value: 'mobile'
          },
          {
            label: 'Компьютер',
            value: 'desktop'
          }
        ],
      },
    },
    {
      label: 'Төлөв',
      name: 'isActive',
      className: 'col-span-6 mb-2',
      type: 'select',
      inputProps: {
        options: Active
      }
    },
     {
      label: 'Зураг',
      name: 'image',
      className: 'col-span-6 mb-2',
      type: 'file',
      rules: [{required: true, message: 'Зураг оруулна уу!'}],
      inputProps: {

      }
    },
  ];
  const handleSubmit = (values) => {
    setLoading(true)

    const path = ref(storage, `banners/${values.image.file.name}`)

    uploadBytes(path, values.image.file).then(res => {
      addDoc(collection(db, "banners"), {
        type: values.ad_typeId,
        status: values.isActive,
        image: values.image.file.name
      }).then(res => {
        message.success("Амжилттай хадгаллаа!")
        navigate(-1)
      }).catch(err => {
        message.error("Баннер хадгалахад алдаа гарлаа!")
      })
    }).catch(err => {
      message.error("Зураг хадгалахад алдаа гарлаа!")
    })

    setLoading(false)
  }


  return (
    <div className='max-w-[1000px] min-w-[700px] mx-auto'>
      <Button size='small' onClick={() => navigate('/banner')} className='flex items-center'><LeftOutlined/>Буцах</Button>
      <div className='bg-white px-4 mt-4'>
        <div className='py-3 border-b font-semibold'>Banner нэмэх</div>
        <Form
          form={form}
          fields={fields}
          onFinish={handleSubmit}
          initValues={{isActive: 1}}
          layout='vertical'
          className='mt-3 gap-x-4'
        >
            <div className='col-span-12 flex justify-end gap-3 py-3'>
            <Button
              type='primary'
              onClick={form.submit}
            >
              Хадгалах
            </Button>

          </div>
        </Form>
      </div>
      {
        loading &&
        <div className="fixed inset-0 bg-gray-200/80 z-[2000] flex justify-center items-center">
          <div className="flex flex-col">
            <Spin>
            </Spin>
            Уншиж байна
          </div>
        </div>
      }
    </div>
  )
}

export default Banner_Add