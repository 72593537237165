import L from 'leaflet'
import { useEffect, useState } from 'react'
import { MapContainer, TileLayer, Polygon, Marker, useMapEvents } from 'react-leaflet'
import data from './mongoliaPolygon.js'

const positionCenter = [47.917262432162865, 106.91808700561523]

const markerIcon = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-icon.png",
  shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});

L.Marker.prototype.options.icon = markerIcon;

const CustomMarker = ({handleChange, setFieldValue, disabled}) => {
  const map = useMapEvents({
    click: (e) => {
      if(!disabled){
        const { lat, lng } = e.latlng;
        handleChange([lat, lng]);
        setFieldValue([lat, lng]);
      }
    }
  });
  return null
}

function Map(props) {
  const [ location, setLocation ] = useState(null)

  useEffect(() => {
    if(props.value){
      if(typeof props.value === 'string'){
        setLocation( Array.isArray(JSON.parse(props.value)) ? JSON.parse(props.value) : null )
        props.onChange(JSON.parse(props.value))
      }
      else{
        props.onChange(props.value)
        setLocation(props.value)
      }
    }
  },[props.value])

  return (
    <MapContainer center={positionCenter} zoom={12} scrollWheelZoom={true} doubleClickZoom={false} {...props} className={`w-full h-[400px] rounded-lg overflow-hidden z-10 ${props.className}`}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {
        data.features.map((state, i) => {
          const coordinates = state.geometry.coordinates[0].map((item) => [item[1], item[0]]);
          return(
            <>
              <Polygon key={i} pathOptions={{weight: 2, opacity: 1, fillOpacity:0}} positions={coordinates}/>
            </>
          )
        })
      }
      <CustomMarker handleChange={setLocation} setFieldValue={props.onChange} disabled={props.disabled}/>
      { location && <Marker position={location}/> }
      <div className={`${props.disabled ? 'block' : 'hidden'} absolute inset-0 z-[400] bg-black bg-opacity-30`}></div>
    </MapContainer>
  )
}

export default Map
