export default [
  {
    label: 'Эрхийн нэр',
    name: 'name',
    rules: [{required: true, message:'Хэрэглэгчийн нэр оруулна уу!'}],
    className: 'col-span-6 mb-2',
    inputProps: {
      className: 'w-full',
    }
  },
  {
    label: 'Хэрэглэгийн жагсаалт цэс',
    name: 'p_systemUser',
    className: 'col-span-12 mb-2',
    type: 'checkbox',
    inputProps: {
      className: 'w-full',
    }
  },
  {
    label: 'Видео мэдээ',
    name: 'p_videoNews',
    className: 'col-span-12 mb-2',
    type: 'checkbox',
    inputProps: {
      className: 'w-full',
    }
  },
  {
    label: 'Баннер',
    name: 'p_advertise',
    className: 'col-span-12 mb-2',
    type: 'checkbox',
    inputProps: {
      className: 'w-full',
    }
  },
  {
    label: 'Хянах самбар',
    name: 'p_dashboard',
    className: 'col-span-12 mb-2',
    type: 'checkbox',
    inputProps: {
      className: 'w-full',
    }
  },
  {
    label: 'Мэдээ засварлах',
    name: 'p_newsEdit',
    className: 'col-span-12 mb-2',
    type: 'checkbox',
    inputProps: {
      className: 'w-full',
    }
  },
  {
    label: 'Нийтлэгч',
    name: 'p_newsPublisher',
    className: 'col-span-12 mb-2',
    type: 'checkbox',
    inputProps: {
      className: 'w-full',
    }
  },
  {
    label: 'Тохиргоо цэс',
    name: 'p_settings',
    className: 'col-span-12 mb-2',
    type: 'checkbox',
    inputProps: {
      className: 'w-full',
    }
  },
  {
    label: 'Системийн хэрэглэгч',
    name: 'p_systemUser',
    className: 'col-span-12 mb-2',
    type: 'checkbox',
    inputProps: {
      className: 'w-full',
    }
  },
]