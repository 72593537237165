import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getStorage } from 'firebase/storage'
import { initializeFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyC3f9Dm04yW57Qk-3qJKAScohgDXErWl4I",
  authDomain: "anduudtravel-a3daa.firebaseapp.com",
  projectId: "anduudtravel-a3daa",
  storageBucket: "anduudtravel-a3daa.appspot.com",
  messagingSenderId: "986318183329",
  appId: "1:986318183329:web:ac400b32edc232ed6f49e0",
  measurementId: "G-JH69M4VH21"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

export const db = initializeFirestore(app, { experimentalForceLongPolling: true, useFetchStreams: false })
export const auth = getAuth(app);
export const storage = getStorage(app)

export default app;