import { Button, Input, Select, message } from "antd";
import { Form } from "components";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { instance } from "utils/axios";
import { CloseOutlined, MinusOutlined } from "@ant-design/icons";
import { doc, getFirestore, updateDoc } from "firebase/firestore";
import { ref, uploadBytes } from "firebase/storage";
import { db, storage } from "utils/firebase";

function EditNews({ data, changeMode, refreshData }) {
  const [editData, setEditData] = useState(null);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState();
  const [subcategories, setSubCategories] = useState([]);
  const [journalists, setJournalists] = useState([]);
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [form] = Form.useForm();

  const navigate = useNavigate();
  const { newsId } = useParams();
  // const db = getFirestore()

  useEffect(() => {
    if (data) {
      setEditData({
        ...data,
      });
    }
  }, [data]);

  const handleLanguageChange = (e) => {
    console.log(data[e])
    if(data[e]) {
      form.setFieldsValue({
        ...data[e],
        ...data
      })
    }
    else {
      form.resetFields()
      // form.setFieldsValue({
      //   ...data,
      //   title: '',
      //   duration: '',
      //   content: '',
      //   pdf: null
      // })
      form.setFieldsValue({
        language: e,
        ...data,
      })
    }
  }

  const fields = [
    {
      type: "component",
      component: (
        <Form.Item className="col-span-12 mb-2" label="Хэл" name="language" rules={[{required: true, message: 'Төрөл заавал оруулана уу!'}]}>
          <Select onChange={handleLanguageChange}>
            <Select.Option value="mn">Монгол</Select.Option>
            <Select.Option value="en">Англи</Select.Option>
            <Select.Option value="kr">Солонгос</Select.Option>
          </Select>
        </Form.Item>
      )
    },
    {
      label: "Гарчиг",
      name: "title",
      className: "col-span-12 mb-2",
      rules: [{required: true, message: 'Гарчиг заавал оруулана уу!'}],
      type: "text",
    },
    {
      label: 'Төрөл',
      name: 'type',
      className: "col-span-12 mb-2",
      rules: [{required: true, message: 'Төрөл заавал оруулана уу!'}],
    },
    {
      label: "Нийтлэл",
      name: "content",
      className: "col-span-12 mb-2",
      rules: [{required: true, message: 'Нийтлэл заавал оруулана уу!'}],
      type: "editor",
    },    
    {
      label: 'Төрөл',
      name: 'type',
      className: "col-span-12 mb-2",
      rules: [{required: true, message: 'Төрөл заавал оруулана уу!'}],
    },
    {
      label: "Зураг",
      name: "image",
      type: "image",
      className: "col-span-12 mb-2",
      rules: [{required: true, message: 'Зураг заавал оруулана уу!'}],
      inputProps: {
        accept: ".png, .jpeg, .jpg, .webp",
      },
    },
    {
      label: "Түргэн Холбоос",
      name: "link",
      className: "col-span-12 mb-2",
    },
  ];

  const handleSubmit = (status) => {
    if (status) {
      setLoading(true);
    } else {
      setSaveLoading(true);
    }
    const values = form.getFieldsValue();
    if(typeof values.image == "string") {
      updateDoc(doc(db, 'news', newsId), {
        [values.language]: {
          title: values.title,
          content: values.content
        },
        image: values.image,
        link: values.link ? values.link : '',
        postDate: moment().format("YYYY-MM-DD HH:mm:ss"),
        status: status
      }).then(() => {
        message.success("Амжилттай заслаа")
        navigate(-1)
      }).catch(err => {
        message.error("Засахад алдаа гарлаа")
      }).then(() => {
        setSaveLoading(false)
      })
    }
    else if(typeof values.image == "object") {
      const path = ref(storage, `news/${values.image.file.name}`)
      uploadBytes(path, values.image.file).then(res => {
        updateDoc(doc(db, 'news', newsId), {
          [values.language]: {
            title: values.title,
            content: values.content
          },
          image: values.image.file.name,
          link: values.link ? values.link : '',
          postDate: moment().format("YYYY-MM-DD HH:mm:ss"),
          status: status
        }).then(() => {
          message.success("Амжилттай заслаа")
          navigate(-1)
        }).catch(err => {
          message.error("Засахад алдаа гарлаа")
        }).then(() => {
          setSaveLoading(false)
        })
      })
    }
  };

  return (
    <div className="bg-white max-w-[1000px] min-w-[700px] mx-auto px-4">
      <div className="py-3 border-b">Мэдээ засах</div>
      <Form
        form={form}
        fields={fields}
        onFinish={handleSubmit}
        editData={editData}
        initValues={{
          ...data,
        }}
        layout="vertical"
        className="mt-3 gap-x-4"
      >
        <div className="col-span-12 flex justify-end gap-3 py-3">
          {data?.status === 0 ? (
            <Button
              type="primary"
              onClick={() => handleSubmit(1)}
              loading={loading}
              disabled={saveLoading}
            >
              Хадгалаад нийтлэх
            </Button>
          ) : null}
          <Button
            type="primary"
            onClick={() => handleSubmit()}
            loading={saveLoading}
            disabled={loading}
          >
            Хадгалах
          </Button>
          <Button
            onClick={() => changeMode(false)}
            disabled={loading || saveLoading}
          >
            Цуцлах
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default EditNews;
