import React, { useState, useEffect, useRef } from 'react';
import { Table, Modal, Button } from 'antd';
import TableFilter from 'utils/TableFilter';
import { Link } from 'react-router-dom';
import { instance } from 'utils/axios';
import { twMerge } from 'tailwind-merge';
import { Form } from 'components';
import fields from './fields';
import editFields from './editFields';
import { FaPen } from 'react-icons/fa';

const status = [
    {label: <div className='text-gray-500'>Идэвхгүй</div>, value: 0},
    {label: <div className='text-green-500'>Идэвхтэй</div>, value: 1}, 
  ]

const UserList = ({}) => {
  const [ data, setData ] = useState([])
  const [editData, setEditData] =useState(null)
  const [ role, setRole ] = useState([])
  const [ loading, setLoading ] = useState(false)
  const [showModal, setShowModal] = useState(false);
  const [ searchedColumn, setSearchedColumn ] = useState('')
  const [ searchText, setSearchText ] = useState('')
  const searchInput = useRef(null)
  const [modal, contextHolder] = Modal.useModal();
  const [ form ] = Form.useForm();

  useEffect(() => {
    callData()
    getRole()
  }, [])

  const callData = () => {
    setLoading(true)
    instance({
      method: 'get',
      url: '/system_users'
    }).then(res => {
      setData(res.data)
    }).catch(err => {
    }).then(() => setLoading(false))
  }
  const getRole = () => {
    instance({
      method: 'get',
      url: '/role'
    }).then(res => {
      let tmp = []
      res.data.map((item, i) => {
        tmp.push({label: item.name, value: item.id})
      })
      setRole(tmp)
    }).catch(err => {
    }).then(() => {
      setLoading(false)
    })
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('')
  };


  const handleSubmit = (values) => {
    setLoading(true);
    const formData = new FormData();
    let keys = Object.keys(values);
    keys.map((item) => {
      formData.append(item, values[`${item}`] || '');
    });
    // formData.set('image', values.file.file ? values.file.file : '');
    if(editData){
      formData.append('id', editData.id);
      instance({
        method: 'put',
        url: `/system_users`,
        headers: { "Content-Type": "multipart/form-data" },
        data: formData,
      }).then((res) => {
        callData();
        handleCancel();
      }).catch((err) => {
      }).finally(() => {
        setLoading(false);
      })
    }else{
      instance({
        method: 'post',
        url: `/system_users`,
        headers: { "Content-Type": "multipart/form-data" },
        data: formData,
      }).then((res) => {
        callData();
        handleCancel();
      }).catch((err) => {
      }).finally(() => {
        setLoading(false);
      })
    }
  };
  const handleAdd = () => {
    setShowModal(true);
  };

  const handleCancel = () => {
    setShowModal(false);
    if(editData){
      setEditData(null);
      form.resetFields();
    }else{
      form.resetFields();
    }
  };

  const handleEdit = (item) => {
    setEditData(item)
    setShowModal(true);
  };
  const columns = [
    {
      title: 'Хэрэглэгчийн нэр',
      name: 'username',
      dataIndex: 'username',
      key: 'username',
      ...TableFilter('username', 'Хэрэглэгчийн нэр', searchInput, handleSearch, handleReset, searchedColumn, searchText, searchInput),
      // render: (text, row) => {
      //   return(
      //     <Link to={`/systemuser/${row.id}`}>{text}</Link>
      //   )
      // }
    },
    {
      title: 'Email',
      name: 'email',
      dataIndex: 'email',
      key: 'email',
      ...TableFilter('email', 'Email', searchInput, handleSearch, handleReset, searchedColumn, searchText, searchInput),
    },
    {
      title: 'Эрх',
      name: 'roleName',
      dataIndex: 'roleName',
      key: 'roleName',
      ...TableFilter('roleName', 'Эрх', searchInput, handleSearch, handleReset, searchedColumn, searchText, searchInput),
    },
    {
      title: 'Төлөв',
      dataIndex: 'isActive',
      key: 'isActive',
      ...TableFilter('isActive', 'Төлөв', searchInput, handleSearch, handleReset, searchedColumn, searchText, searchInput),
      render: (text, item) => {
        return(
            <span className={twMerge('font-semibold', text == 1 ? 'text-green-500' : 'text-gray-500')}>
              {text == 1 ? 'Идэвхтэй' : 'Идэвхгүй'}
            </span>
          )
        }
    },
    {
      title: '',
      key: 'action',
      align: 'center',
      render: (text, item) => (
        <span className='flex gap-2'>
          <Button size='small' className='border-none bg-transparent hover:bg-transparent text-[#005594]' onClick={() => handleEdit(item)}><FaPen/></Button>
        </span>
      ),
    },
  ];

  return (
    <>
      <div className='flex justify-between bg-white py-2 px-3 border-b'>
        <span> Системийн хэрэглэгч <span>({data.length})</span></span>
        <Button type='primary' size='small' onClick={() => handleAdd()}>
          Нэмэх
        </Button>
      </div>
        <Table
          columns={columns}
          dataSource={data}
          rowKey={record => record.id}
          size='small'
          loading={loading}
          pagination={{
            defaultPageSize: 20
          }}
        >
      </Table>
      <Modal
        title={editData ? 'Засах' : 'Шинээр нэмэх'}
        open={showModal}
        onCancel={handleCancel}
        footer={[
          <Button key='cancel' onClick={handleCancel}>
            Цуцлах
          </Button>,
          <Button key='edit' type='primary' loading={loading} onClick={() => form.submit()}>
            Хадгалах
          </Button>,
        ]}
      >
        {
          editData ? 
          <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            fields={editFields({ role: role})}
            editData={editData}
            className='gap-4' 
          />
          :
          <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            fields={fields({ role: role})}
            editData={editData}
            className='gap-4' 
          />
        }
      </Modal>
      {contextHolder}
    </>
  );
}

export default UserList;
