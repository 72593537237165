import {Button, Input, Select } from 'antd';
import { Form } from 'components';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons'
import { instance } from 'utils/axios';

const Active = [
  {label: <div className='flex items-center gap-2'>Идэвхгүй</div>, value: 0},
  {label: <div className='flex items-center gap-2'>Идэвхтэй</div>, value: 1}, 
]

function Banner_detail() {
  const [ editData, setEditData ] = useState(null)
  const [ data, setData ] = useState(null)
  const [ ad_type, setAd_type ] = useState([])
  const [ size, setSize ] = useState([])
  const [ journalists, setJournalists ] = useState([])
  const [ loading, setLoading ] = useState(false)
  const [ form ] = Form.useForm()

  const navigate = useNavigate()
  const { newsId } = useParams()

  useEffect(() => {
    if(data){
      setEditData({
        ...data,
  })
    }
      },[data])

  useEffect(() => {
    callData()
    getAd_type()
  },[])

  const callData = () => {
    instance({
      method: 'get',
      url: `web_a/${newsId}`
    }).then(res => {
      setData(res.data);
    }).catch(err => {
    }).then(() => {
      setLoading(false)
    })
  }
  const getAd_type = () => {
    instance({
      method: 'get',
      url: '/b_type'
    }).then(res => {
      let tmp = []
      res.data.map((item, i) => {
        tmp.push({label: `${item.adType} - ${item.size}`, value: item.id });
      })
      setAd_type(tmp)
      setSize(tmp)
    }).catch(err => {
    }).then(() => {
      setLoading(false)
    })
  }

  const fields = [
    {
      label: 'зарын төрөл',
      // size: 'size',
      name: 'ad_typeId',
      className: 'col-span-6 mb-2',
      type: 'select',
      inputProps: {
        options: ad_type,
      },
    },
    {
      label: 'Холбоос',
      name: 'link',
      className: 'col-span-6 mb-2',
      type: 'text',
    },
   
    {
      label: 'Эхлэх цаг',
      name: 'startTime',
      className: 'col-span-6 mb-2',
      type: 'date',
    },
    {
      label: 'Дуусах цаг',
      name: 'endTime',
      className: 'col-span-6 mb-2',
      type: 'date',
    },
    {
      label: 'Төлөв',
      name: 'isActive',
      className: 'col-span-6 mb-2',
      type: 'select',
      inputProps: {
        options: Active
      }
    },
     {
      label: 'Зураг',
      name: 'image',
      className: 'col-span-6 mb-2',
      type: 'file',
      inputProps: {
        // aspect: [1/1]
      }
    },
  ];
  const handleSubmit = (values) => {
    instance({
      method: 'put',
      url: `/web_a`,
      data: {
        id: newsId,
        ...values,
      },
    }).then((res) => {

    }).catch((err) => {

    }).finally(() => {
      navigate('/editor/banner')

    })
  }
  return (
    <div className='max-w-[1000px] min-w-[700px] mx-auto'>
      <Button size='small' onClick={() => navigate('/editor/banner')} className='flex items-center'><LeftOutlined/>Буцах</Button>
      <div className='bg-white px-4 mt-4'>
        <div className='py-3 border-b font-semibold'>Banner засах</div>
        <Form
          form={form}
          fields={fields}
          onFinish={handleSubmit}
          editData={data}
          layout='vertical'
          className='mt-3 gap-x-4'
        >
          <div className='col-span-12 flex justify-end gap-3 py-3'>
            <Button
              type='primary'
              onClick={form.submit}
            >
              Хадгалах
            </Button>

          </div>
        </Form>
      </div>
    </div>
  )
}
export default Banner_detail