import {Button, Input, Select } from 'antd';
import { Form } from 'components';  
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons'
import { instance } from 'utils/axios';

const Active = [
  {label: <div className='flex items-center gap-2'>Идэвхгүй</div>, value: 0},
  {label: <div className='flex items-center gap-2'>Идэвхтэй</div>, value: 1}, 
]

function SystemUser_Edit() {
  const [ editData, setEditData ] = useState(null)
  const [ data, setData ] = useState(null)
  const [ role, setRole ] = useState([])
  const [ loading, setLoading ] = useState(false)
  const [ form ] = Form.useForm()
  const navigate = useNavigate()
  const { newsId } = useParams()

  useEffect(() => {
    if(data){
      setEditData({
        ...data,
        name: data?.name,
            })
    }
  },[data])

  useEffect(() => {
    getData()
    getRole()
  },[])
  const getData = () => {
    instance({
      method: 'get',
      url: `/system_user/${newsId}`
    }).then(res => {
      setData(res.data);
    }).catch(err => {
    }).then(() => {
      setLoading(false)
    })
  }
 
  const getRole = () => {
    instance({
      method: 'get',
      url: '/role'
    }).then(res => {
      let tmp = []
      res.data.map((item, i) => {
        tmp.push({label: item.name, value: item.id})
      })
      setRole(tmp)
    }).catch(err => {
    }).then(() => {
      setLoading(false)
    })
  }
  const fields = [
  
    {
      label: 'Хэрэглэгчийн нэр',
      name: 'username',
      className: 'col-span-6 mb-2',
      type: 'text',
    },
    {
      label: 'Утас',
      name: 'phone',
      className: 'col-span-6 mb-2',
      // type: 'number',
    },
    {
        label: 'Email',
        name: 'email',
        className: 'col-span-6 mb-2',
        type: 'text',
        rules: [
          {
            type: 'email',
            message: 'Email хаяг оруулна уу',
          },
          {
            message: 'Email шаардлагатай',
          },
        ],
      },
    
      {
        label: 'Хаяг',
        name: 'linkedInAddress',
        className: 'col-span-6 mb-2',
        type: 'text',
      },
       {
      label: 'Төлөв',
      name: 'isActive',
      className: 'col-span-3  mb-2',
      type: 'select',
      inputProps: {
        options: Active,
      },
      initialValue: editData ? editData.isActive : undefined,
    }, 
    {
      label: 'Үүрэг',
      name: 'roleId',
      className: 'col-span-3 mb-2',
      inputProps: {
        options: role,
      },
      type: 'select',
    },  
    {
        label: 'Профайл Зураг',
        name: 'profile_image',
        type: 'image',
        className: 'col-span-6 mb-2',
        inputProps: {
          accept: '.png, .jpeg, .jpg, .webp'
        }
      },
  ];
  const handleSubmit = (values) => {
    console.log('asaas',values)
    const formData = new FormData;
    formData.append('username', values.username)
    formData.append('email', values.email)
    formData.append('phone', values.phone)
    formData.append('linkedInAddress', values.linkedInAddress)
    formData.append('image', values.profile_image?.file ? values.profile_image?.file : '')
    formData.append('id', newsId)
    instance({
      method: 'put',
      url: '/system_users',
      data: formData
    }).then((res) => {
    }).catch((err) => {

    }).finally(() => {
      navigate('/editor/systemuser')

    })
  
  }
  return (
    <div className='max-w-[1000px] min-w-[700px] mx-auto'>
      <Button size='small' onClick={() => navigate('/editor/systemuser')} className='flex items-center'><LeftOutlined/>Буцах</Button>
      <div className='bg-white px-4 mt-4'>
        <div className='py-3 border-b font-semibold'>Системийн хэрэглэгч засах</div>
        <Form
          form={form}
          fields={fields}
          onFinish={handleSubmit}
          editData={data}
          layout='vertical'
          className='mt-3 gap-x-4'
        >
          <div className='col-span-12 flex justify-end gap-3 py-3'>
            <Button
              type='primary'
              onClick={form.submit}
            >
              Хадгалах
            </Button>

          </div>
        </Form>
      </div>
    </div>
  )
}
export default SystemUser_Edit