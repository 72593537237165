import React, { useState, useEffect, useRef } from 'react';
import { Table, Modal, Tag, Progress, Badge, Switch, Button, Checkbox } from 'antd';
import TableFilter from 'utils/TableFilter';
import { Link } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router-dom';
import { instance } from 'utils/axios';
import { twMerge } from 'tailwind-merge';
import { FaPen, FaTrash } from 'react-icons/fa';
import fields from './fields';
import { Form } from 'components';

const HomeCategory = ({}) => {
  const [ data, setData ] = useState([])
  const [ editData, setEditData ] =useState(null)
  const [showModal, setShowModal] = useState(false);
  const [ loading, setLoading ] = useState(false)
  const [ searchedColumn, setSearchedColumn ] = useState('')
  const [ searchText, setSearchText ] = useState('')
  const searchInput = useRef(null)
  const navigate = useNavigate()
  const [ form ] = Form.useForm();
  const [modal, contextHolder] = Modal.useModal();

  const status = [
    {label: <div className='flex items-center gap-2'>Идэвхгүй</div>, value: 0},
    {label: <div className='flex items-center gap-2'>Идэвхтэй</div>, value: 1}, 
  ]

  useEffect(() => {
    callData()
  }, [])

  const callData = () => {
    setLoading(true)
    instance({
      method: 'get',
      url: '/homecategory'
    }).then(res => {
      let cats = res.data.sort((a, b) => {
        if(a.homeOrder > b.homeOrder){
          return 1
        }
        if(a.homeOrder < b.homeOrder){
          return -1
        }
        return 0
      })
      setData([...cats])
    }).catch(err => {
    }).then(() => setLoading(false))
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('')
  };

  const handleRowDeleteClick = (data, e) => {
    e.stopPropagation()
    modal.confirm({
      title: 'Анхаарна уу?',
      content: 'Энэхүү мөр бичлэгийг устгах гэж байна',
      okText: 'Устгах',
      cancelText: 'Буцах',
      onOk() {
        return new Promise((resolve, reject) => {
          if(data.categoryId){
            instance({
              method: 'put',
              url: `/sub_category`,
              data: {
                isHome: 0,
                id: data.id,
              },
            }).then(res => {
              resolve()
            }).catch(err => {
              reject()
            }).then(() => {
              callData()
            })
          }else{
            instance({
              method: 'put',
              url: `/category`,
              data: {
                isHome: 0,
                id: data.id,
              },
            }).then(res => {
              resolve()
            }).catch(err => {
              reject()
            }).then(() => {
              callData()
            })
          }
        }).catch(() => {});
      },
      onCancel() {},
    });
  }

  const columns = [
    {
      title: '№',
      name: 'homeOrder',
      dataIndex: 'homeOrder',
      key: 'homeOrder',
      width: 40,
    },
    {
      title: 'Нэр',
      name: 'name',
      dataIndex: 'name',
      key: 'name',
      ...TableFilter('name', 'Нэр', searchInput, handleSearch, handleReset, searchedColumn, searchText, searchInput),
      render: (text, row) => {
        return(
          <Link className='text-primary hover:text-primary hover:underline' to={`/editor/category/${row.id}`}>{text}</Link>
      )
      }
    },
    {
      title: 'Slug',
      dataIndex: 'slug',
      key: 'slug',
      ...TableFilter('slug', 'Slug', searchInput, handleSearch, handleReset, searchedColumn, searchText, searchInput),
    },
    {
      title: 'Ангилалын төрөл',
      dataIndex: 'categoryId',
      render: (text, row) => (
        <span>{row.categoryId ? 'Дэд' : 'Үндсэн'}</span>
      )
    },
    {
      title: '',
      key: 'action',
      align: 'center',
      width: 90,
      render: (text, item) => (
        <span className='flex gap-2'>
          <Button success size='small' className='border-none bg-transparent hover:bg-transparent text-[#005594]' onClick={() => handleEdit(item)} ><FaPen/></Button>
          <Button danger size='small' onClick={(e) => handleRowDeleteClick(item, e)}><FaTrash/></Button>
        </span>
      ),
    },
  ];

  const handleSubmit = (values) => {
    setLoading(true);
    if(editData.categoryId){
      instance({
        method: 'put',
        url: `/sub_category`,
        data: {
          ...values,
          id: editData.id,
        },
      }).then((res) => {
        callData();
        handleCancel();
      }).catch((err) => {
      }).finally(() => {
        setLoading(false);
      })
    }else{
      instance({
        method: 'put',
        url: `/category`,
        data: {
          ...values,
          id: editData.id,
        },
      }).then((res) => {
        callData();
        handleCancel();
      }).catch((err) => {
      }).finally(() => {
        setLoading(false);
      })
    }
  };

  const handleAdd = () => {
    setEditData(null);
    form.resetFields()
    setShowModal(true);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleEdit = (item) => {
    setEditData(item)
    setShowModal(true);
  };

  return (
    <>
      <div className='flex justify-between bg-white py-2 px-3 border-b'>
        <span>Home categories <span>({data.length})</span></span>
        {/* <Link to={'/category/add'}> */}
          <Button type='primary' size='small' onClick={handleAdd}>
            Нэмэх
          </Button>
        {/* </Link> */}
      </div>
        <Table
          columns={columns}
          dataSource={data}
          rowKey={record => record.slug}
          size='small'
          loading={loading}
          pagination={{
            defaultPageSize: 20
          }}
        >
      </Table>
      <Modal
        title={editData ? 'Үндсэн ангилал засах' : 'Үндсэн ангилал нэмэх'}
        open={showModal}
        onCancel={handleCancel}
        footer={[
          <Button key='cancel' onClick={handleCancel}>
            Цуцлах
          </Button>,
          <Button key='edit' type='primary' loading={loading} onClick={() => form.submit()}>
            Хадгалах
          </Button>,
        ]}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          fields={fields({ status: status })}
          initValues={{sequence: data.length+1}}
          editData={editData}
          className='gap-4' 
        />
      </Modal>
      {contextHolder}
    </>
  );
}

export default HomeCategory;
