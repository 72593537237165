import { Button, Skeleton } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { instance } from "utils/axios";
import EditNews from "./EditNews";
import { AuthContext } from "contexts";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { Image } from "components";
import { db } from "utils/firebase";

function NewsDetail() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const { state } = useContext(AuthContext)
  const { newsId } = useParams();
  const navigate = useNavigate();
  // const db = getFirestore()

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true);
    const docRef = doc(db, 'news', newsId)
    getDoc(docRef).then(res => {
      setData(res.data())
    }).catch(err => {

    }).then(() => {
      setLoading(false)
    })
  };

  const handlePublish = () => {
    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("content", data.content);
    formData.append("caption", data?.caption ? data?.caption : '');
    formData.append("source", data?.source ? data?.source : '');
    formData.append("quickLink", data.quickLink ? data.quickLink : '');
    formData.append("postDate", moment(data.postDate).format('YYYY-MM-DD HH:mm'));
    formData.append("isFeaturedNews", data.isFeaturedNews ? 1 : 0);
    formData.append("isTop", data.isTop);
    formData.append("isPaid", data.isPaid ? 1 : 0);
    formData.append("isComment", data.isComment ? 1 : 0);
    formData.append("categoryId", data.categoryId);
    formData.append("sub_categoryId", data.sub_categoryId);
    formData.append("journalistId", data.journalistId);
    formData.append("news_key_notes", JSON.stringify(data.news_key_notes));
    formData.append("image", data?.thumbnail);
    formData.append("status", 2);
    formData.append("id", newsId);
    instance({
      method: "put",
      url: "/news",
      data: formData,
    }).then((res) => {
      getData();
    }).catch((err) => {

    }).finally(() => {});
  }
  
  return (
    <div>
      {loading ? (
        <Skeleton className="max-w-[1029px] mx-auto" />
      ) : (
        <>
          {isEdit ? (
            <EditNews
              data={data}
              changeMode={(mode) => setIsEdit(mode)}
              refreshData={getData}
            />
          ) : (
            <div className="bg-white px-5 py-6 max-w-[1029px] mx-auto">
              <div className="text-[34px] leading-[38px] font-bold">
                {data?.title}
              </div>
              <div className="text-[20px] leading-[24px] font-medium">
                {data?.type}
              </div>
              <div className="flex">
                <div className="flex-1">
                  <div className="mt-4 font-semibold text-[12px] leading-[16px] text-[#747474]">
                    Нийтэлсэн &nbsp;{" "}
                    {moment(data?.postDate).format(
                      "YYYY оны MM сарын DD HH:mm"
                    )}
                  </div>
                  <div className="border-b-4 border-primary mt-5 mb-[30px]"></div>
                  <div className="border-b border-dashed pb-[15px] mb-[20px]">
                    <Image
                      base="news"
                      src={`${data?.image}`}
                      className="w-full"
                    />
                  </div>
                  <div dangerouslySetInnerHTML={{ __html: data?.content }} />
                </div>
                <div className="w-[300px] ml-[30px]"></div>
              </div>
              <div className="flex justify-between">
                <Button onClick={() => navigate("/news")}>Буцах</Button>
                <div className="flex gap-5">
                  {
                    data?.status === 1 ? 
                    <Button type="primary" onClick={handlePublish}>
                      Нийтлэх
                    </Button>
                    : null
                  }
                  {
                    <Button onClick={() => setIsEdit(true)}>
                      Засах
                    </Button>
                  }
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default NewsDetail;
