import { Button, Input, Select, Space, message } from "antd";
import { Form } from "components";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import { instance } from "utils/axios";
import { CloseOutlined, MinusOutlined, PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { ref, uploadBytes } from "firebase/storage";
import { addDoc, collection, getFirestore } from "firebase/firestore";
import { db, storage } from "utils/firebase";

function TravelAdd() {
  const [ categories, setCategories ] = useState([]);
  const [ subcategories, setSubCategories ] = useState([]);
  const [ journalists, setJournalists ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const [ saveLoading, setSaveLoading ] = useState(false)
  const [ form ] = Form.useForm();
  
  // const db = getFirestore()

  const navigate = useNavigate();

  useEffect(() => {
    form.setFieldValue("language", "mn")
  }, []);

  const fields = [
    {
      label: 'Хэл',
      name: 'language',
      className: "col-span-12 mb-2",
      rules: [{required: true, message: 'Төрөл заавал оруулана уу!'}],
      type: "select",
      inputProps: {
        options: [
          {
            label: 'Монгол',
            value: 'mn'
          },
          {
            label: 'Англи',
            value: 'en'
          },
          {
            label: 'Солонгос',
            value: 'kr'
          }
        ],
        defaultValue: 'mn'
      },
    },
    {
      label: 'Төрөл',
      name: 'type',
      className: "col-span-12 mb-2",
      rules: [{required: true, message: 'Төрөл заавал оруулана уу!'}],
      type: "select",
      inputProps: {
        options: [
          {
            label: 'Гадаад',
            value: 'foreign'
          },
          {
            label: 'Дотоод',
            value: 'domestic'
          },
          {
            label: 'Гадаадуудад',
            value: 'forForeign'
          }
        ],
      },
    },
    {
      label: 'Категори',
      name: 'category',
      className: "col-span-12 mb-2",
      rules: [{required: true, message: 'Категори заавал оруулана уу!'}],
      type: "select",
      inputProps: {
        options: [
          {
            label: 'Travel',
            value: 0
          },
          {
            label: 'Day trips',
            value: 1
          },
          {
            label: 'City tour',
            value: 2
          },
          {
            label: 'Events',
            value: 3
          },
          {
            label: 'Sports Activities',
            value: 4
          },
        ],
      },
    },
    {
      label: "Гарчиг",
      name: "title",
      className: "col-span-12 mb-2",
      rules: [{required: true, message: 'Гарчиг заавал оруулана уу!'}],
      type: "text",
    },
    {
      label: "Хугацаа",
      name: "duration",
      className: "col-span-12 mb-2",
      rules: [{required: true, message: 'Хугацаа заавал оруулана уу!'}],
      type: "text",
    },
    {
      label: "Нийтлэл",
      name: "content",
      className: "col-span-12 mb-2",
      rules: [{required: true, message: 'Нийтлэл заавал оруулана уу!'}],
      type: "editor",
    },
    {
      label: "Аялсан хүний тоо",
      name: "traveledNumbers",
      className: "col-span-12 mb-2",
    },
    {
      label: "Файл",
      name: "image",
      type: "image",
      className: "col-span-12 mb-2",
      rules: [{required: true, message: 'Файл заавал оруулана уу!'}],
      inputProps: {
        accept: ".png, .jpeg, .jpg, .webp",
      },
    },
    {
      label: "Зургийн цомог",
      name: "covers",
      type: "component",
      className: "col-span-12 mb-2",
      component: <Form.List name="covers" label="Ковер">
        {(fields, { add, remove}, { errors}) => (
          <>
          <label className="text-[#85878E]">Ковер</label>
          {
            fields.map(({key, name, ...restField}) => {
              return (
                <div key={key} className="col-span-12 flex items-baseline gap-2">
                  <Form.Item
                    name={[name]}
                    {...restField}>
                    <Input placeholder="Ковер линк">
                    
                    </Input>
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </div>
              )
            })
          }
          <Form.Item className="col-span-12">
            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
              Ковер нэмэх
            </Button>
          </Form.Item>
          </>
        )}
      </Form.List>
    },
    {
      label: "Танилцуулага файл",
      name: "pdf",
      type: "image",
      className: "col-span-12 mb-2",
      inputProps: {
        accept: ".pdf",
        maxCount: 1
      },
    },
  ];

  const handleSubmit = (status) => {
    setLoading(true)

    const values = form.getFieldsValue();
    let promises = []
    const path = ref(storage, `travels/${values.image.file.name}`)
    promises.push(uploadBytes(path, values.image.file))
    let pdfPath
    if(values.pdf) {
      pdfPath = ref(storage, `travels/${values.language}/${values.pdf.file.name}`)
      promises.push(uploadBytes(pdfPath, values.pdf.file))
    }
    Promise.all([promises]).then(res => {
      addDoc(collection(db, "travels"), {
        [values.language]: {
          title: values.title,
          duration: values.duration,
          content: values.content,
          pdf: values.pdf ? values.pdf.file.name : null
        },
        image: values.image.file.name,
        type: values.type,
        covers: values.covers? values.covers : [],
        category: values.category
      }).then(res => {
        message.success("Аялал амжилттай нэмлээ")
        navigate(-1)
      }).catch(err => {
        console.log(err)
        message.error("Аялал хадгалахад алдаа гарлаа!")
      }).then(() => {
        setLoading(false)
      })
    }).catch(err => {
      console.log(err)
      message.error("Зураг хадгалахад алдаа гарлаа!")
    }).then(() => {
      setLoading(false)
    })
  };

  return (
    <div className="max-w-[1000px] min-w-[700px] mx-auto">
      <Button
        size="small"
        onClick={() => navigate("/travel")}
        className="flex items-center"
      >
        <LeftOutlined />
        Буцах
      </Button>
      <div className="bg-white px-4 mt-4">
        <div className="py-3 border-b">Аялал нэмэх</div>
        <Form
          form={form}
          fields={fields}
          onFinish={handleSubmit}
          // closeModal={() => setShowModal(false)}
          // loading={loading}
          initValues={{
            categories: [{}]
          }}
          layout="vertical"
          className="mt-3 gap-x-4"
        >
          <div className="col-span-12 flex justify-end gap-3 py-3">
            <Button type="primary" onClick={() => handleSubmit(1)} loading={loading} disabled={saveLoading}>
              Нийтлэх
            </Button>
            <Button onClick={() => navigate("/editor/news")} disabled={loading || saveLoading}>Цуцлах</Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default TravelAdd;
