import React, { useState, useEffect, useRef, useContext } from "react";
import { Table, Modal, Tag, Progress, Badge, Switch, Button, message } from "antd";
import axios from "axios";
import TableFilter from "utils/TableFilter";
import { Form } from "components";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import ls from "utils/ls";
import { instance } from "utils/axios";
import { twMerge } from "tailwind-merge";
import { AuthContext } from "contexts";
import { getFirestore, addDoc, collection, getDocs, deleteDoc, doc } from 'firebase/firestore'
import { db } from "utils/firebase";

const renderStatus = (status) => {
  switch (status) {
    case 0: return 'Хадгалсан'
    case 1: return 'Хүлээгдэж буй'
    case 2: return 'Зөвшөөрсөн'
    case 3: return 'Нийтэлсэн'
    case 4: return 'Устгасан'
  }
}

const getTagColor = (status) => {
  switch (status) {
    case 0: return ''
    case 1: return 'warning'
    case 2: return 'proccessing'
    case 3: return 'success'
    case 4: return 'error'
  }
}

const typeStatus = (status) => {
  switch (status) {
    case 'foreign': return 'Гадаад'
    case 'domestic': return 'Дотоод'
  }
}

const Travel = ({}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchedColumn, setSearchedColumn] = useState("");
  const [searchText, setSearchText] = useState("");
  const searchInput = useRef(null);
  const [modal, contextHolder] = Modal.useModal();
  const { state } = useContext(AuthContext)
  // const db = getFirestore()
  const navigate = useNavigate()

  useEffect(() => {
    callData()
  }, []);

  const callData = () => {
    setLoading(true);
    getDocs(collection(db, 'travels')).then(res => {
      let tmp = []
      res.forEach(foo => {
        console.log(foo.data())
        tmp.push({...foo.data(), id: foo.id})
      })
      console.log(tmp)
      setData(tmp)
    }).catch(err => {
      console.log(err)
    }).then(() => {
      setLoading(false)
    })
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const handleRowDeleteClick = (data, e) => {
    e.stopPropagation();
    modal.confirm({
      title: "Анхаарна уу?",
      content: "Энэхүү мөр бичлэгийг устгах гэж байна",
      okText: "Устгах",
      cancelText: "Буцах",
      onOk() {
        return new Promise((resolve, reject) => {
          deleteDoc(doc(db, 'travels', data.id)).then(res => {
            message.success("Амжилттай устгалаа")
            resolve()
          }).catch(err => {
            message.error("Устгахад алдаа гарлаа")
            reject()
          }).then(() => {
            callData()
          })
        }).catch(() => {});
      },
      onCancel() {},
    });
  };

  const columns = [
    {
      title: "Гарчиг",
      name: "title",
      dataIndex: "title",
      key: "title",
      ...TableFilter(
        "title",
        "гарчиг",
        searchInput,
        handleSearch,
        handleReset,
        searchedColumn,
        searchText,
        searchInput
      ),
      width: 300,
      render: (text, row) => {
        return <Link className='text-primary hover:text-primary hover:underline' to={`/travel/${row.id}`}>{row.mn ? row.mn.title : row.en ? row.en.title : row.kr ? row.kr.title : row.title}</Link>
      },
    },
    {
      title: "Төрөл",
      dataIndex: "type",
      key: "type",
      // width: 200,
      render: (text, item) => <span>{typeStatus(text)}</span>,
    },
    {
      title: "Категори",
      dataIndex: "category",
      key: "category",
      // width: 200,
      render: (text, item) => <span>{typeStatus(text)}</span>,
    },
    {
      title: "Хугацаа",
      dataIndex: "duration",
      key: "duration",
      // width: 200,
      render: (text, row) => {
        return <Link className='text-primary hover:text-primary hover:underline' to={`/travel/${row.id}`}>{row.mn ? row.mn.duration : row.en ? row.en.duration : row.kr ? row.kr.duration : row.duration}</Link>
      },
    },
    {
      title: "Төлөв",
      dataIndex: "status",
      key: "status",
      // width: 200,
      render: (text, item) => {
        return (
          <Tag color={getTagColor(text)}>
            {renderStatus(text)}
          </Tag>
        );
      },
    },
    {
      title: "Хэдэн хүн аялсан",
      dataIndex: "traveledNumbers",
      key: "traveledNumbers",
      // width: 200,
      render: (text, item) => {
        return (
          <Tag>
            {text}
          </Tag>
        );
      },
    },
    {
      title: "",
      key: "action",
      align: "center",
      width: 70,
      render: (text, item) => (
        <span className="flex">
          {
            <Button
              danger
              size="small"
              onClick={(e) => handleRowDeleteClick(item, e)}
            >
              Устгах
            </Button>
          }
        </span>
      ),
    },
  ];

  return (
    <>
      <div className="flex justify-between bg-white py-2 px-3 border-b">
        <span>
          {" "}
           Аялал <span>({data.length})</span>
        </span>
        <Link to={"/travel/add"}>
          <Button type="primary" size="small">
            Нэмэх
          </Button>
        </Link>
      </div>
      <Table
        columns={columns}
        dataSource={data}
        rowKey={(record) => record.id}
        size="small"
        loading={loading}
        onRow={(record, recordIndex) => {
          return {
            onClick: e => navigate(`/travel/${record.id}`)
          }
        }}
        pagination={{
          defaultPageSize: 20,
        }}
      ></Table>
      {contextHolder}
    </>
  );
};

export default Travel;
