import React from 'react'
import { Link } from 'react-router-dom'

function Settings() {
  return (

    <div className='flex w-full justify-center bg-white border-b'>
      <div className=' rounded-3xl w-full max-w-[800px] min-w-[500px] p-4'>
        <div className='font-medium text-4xl text-black-text'>Системийн тохиргоо</div>
        <div className='flex flex-col mt-5 gap-3 text-black-text font-medium '>
          <Link className='p-4 bg-cardbg rounded-[6px]' to={'all'}>Бүх</Link>
        </div>
      </div>
    </div>
  )
}

export default Settings