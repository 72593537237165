import React, { useState, useEffect, useRef } from 'react';
import { Table, Modal, Button, Tabs } from 'antd';
import TableFilter from 'utils/TableFilter';
import { Link, useNavigate } from 'react-router-dom';
import { instance } from 'utils/axios';
import { twMerge } from 'tailwind-merge';

const UserList = ({}) => {
  const [ data, setData ] = useState([])
  const [ loading, setLoading ] = useState(false)
  const [ searchedColumn, setSearchedColumn ] = useState('')
  const [ searchText, setSearchText ] = useState('')
  const searchInput = useRef(null)
  const [modal, contextHolder] = Modal.useModal();
  const navigate = useNavigate()


  useEffect(() => {
    // callData()
  }, [])

  const callData = () => {
    setLoading(true)
    instance({
      method: 'get',
      url: '/customers'
    }).then(res => {
      setData(res.data)
    }).catch(err => {
    }).then(() => setLoading(false))
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('')
  };


  const columns = [
    {
      title: 'Зураг',
      name: 'profile_image',
      dataIndex: 'profile_image',
      key: 'profile_image',
      ...TableFilter('profile_image', 'Зураг', searchInput, handleSearch, handleReset, searchedColumn, searchText, searchInput),
      render: (text, row) => {
        return(
          <img width={40} src={`${process.env.REACT_APP_CDN_URL}${text}`}  alt={`image-${row?.id}`} />
          )
      }
    },
    {
        title: 'Нэр',
        name: 'firstname',
        dataIndex: 'firstname',
        key: 'firstname',
        ...TableFilter('name', 'Нэр', searchInput, handleSearch, handleReset, searchedColumn, searchText, searchInput),
        // render: (text, row) => {
        //   return(
        //     <Link to={`/users/${row.id}`}>{text}</Link>
        //   )
        // }
      },
      {
        title: 'Овог',
        name: 'lastname',
        dataIndex: 'lastname',
        key: 'lastname',
        ...TableFilter('lastname', 'Овог', searchInput, handleSearch, handleReset, searchedColumn, searchText, searchInput),
        // render: (text, row) => {
        //   return(
        //     <Link to={`/users/${row.id}`}>{text}</Link>
        //   )
        // }
      },
      {
        title: 'Утас',
        name: 'phone',
        dataIndex: 'phone',
        key: 'phone',
        ...TableFilter('phone', 'Утас', searchInput, handleSearch, handleReset, searchedColumn, searchText, searchInput),
      },
    {
      title: 'Email',
      name: 'email',
      dataIndex: 'email',
      key: 'email',
      ...TableFilter('email', 'Email', searchInput, handleSearch, handleReset, searchedColumn, searchText, searchInput),
      // render: (text, row) => {
      //   return(
      //     <Link to={`/users/${row.id}`}>{text}</Link>
      //   )
      // }
    },
    {
      title: 'Төлөв',
      dataIndex: 'isActive',
      key: 'isActive',
      ...TableFilter('isActive', 'Төлөв', searchInput, handleSearch, handleReset, searchedColumn, searchText, searchInput),
       render: (text, item) => {
        return(
            <span className={twMerge('font-semibold', text == 1 ? 'text-green-500' : 'text-gray-500')}>
              {text == 1 ? 'Идэвхтэй' : 'Идэвхгүй'}
            </span>
          )
        }
    },
    {
      title: 'Бүртгэсэн огноо',
      name: 'createdAt',
      dataIndex: 'createdAt',
      key: 'createdAt',
      ...TableFilter('createdAt', 'Төлбөр', searchInput, handleSearch, handleReset, searchedColumn, searchText, searchInput),
      render: (text, row) => {
        return(
          <span>
            {text}
          {/* {dayjs(text)('YYYY-MM-DD')} */}
        </span>
        )
      }
    },
  ];

  return (
    <>
      <div className='flex justify-between bg-white py-2 px-3 border-b'>
        <span> Системийн хэрэглэгч <span>({data.length})</span></span>
      </div>
      <Tabs
        className="bg-white"
        tabBarStyle={{
          padding: '0 16px'
        }}
        items={[
          {
            label: 'Аялал',
            key: 0,
            children: <Table
                        columns={columns}
                        dataSource={data}
                        rowKey={record => record.id}
                        size='small'
                        loading={loading}
                        pagination={{
                          defaultPageSize: 20
                        }}
                      >
                      </Table>
          },
          {
            label: 'Ачаа тээвэр',
            key: 1,
            children: <Table
                        columns={columns}
                        dataSource={data}
                        rowKey={record => record.id}
                        size='small'
                        loading={loading}
                        pagination={{
                          defaultPageSize: 20
                        }}
                      >
                      </Table>
          },
          {
            label: 'Тийз',
            key: 2,
            children: <Table
                        columns={columns}
                        dataSource={data}
                        rowKey={record => record.id}
                        size='small'
                        loading={loading}
                        pagination={{
                          defaultPageSize: 20
                        }}
                      >
                      </Table>
          },
          {
            label: 'Холбоо барих',
            key: 3,
            children: <Table
                        columns={columns}
                        dataSource={data}
                        rowKey={record => record.id}
                        size='small'
                        loading={loading}
                        pagination={{
                          defaultPageSize: 20
                        }}
                      >
                      </Table>
          },
        ]}>

      </Tabs>
      {contextHolder}
    </>
  );
}

export default UserList;
