import React, { useState, useEffect, useRef } from 'react';
import { Table, Modal, Tag, Progress, Badge, Switch, Button, message } from 'antd';
import axios from 'axios';
import TableFilter from 'utils/TableFilter';
import { Form, Image } from 'components';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ls from 'utils/ls';
import { instance } from 'utils/axios';
import { twMerge } from 'tailwind-merge';
import { storage, db } from 'utils/firebase';
import { deleteObject, getDownloadURL, listAll, ref } from 'firebase/storage';
import { collection, deleteDoc, doc, getDocs, getFirestore } from 'firebase/firestore';

const Banner = ({}) => {
  const [ data, setData ] = useState([])
  const [ Ad_type, setAd_type ] = useState([])
  const [ journalists, setJournalists ] = useState([])
  const [ loading, setLoading ] = useState(true)
  const [ searchedColumn, setSearchedColumn ] = useState('')
  const [ searchText, setSearchText ] = useState('')
  const [ showModal, setShowModal ] = useState(false)
  const [ edit, setEdit ] = useState(null)
  const searchInput = useRef(null)
  const [ form ] = Form.useForm()
  const [modal, contextHolder] = Modal.useModal();
  // const db = getFirestore()
  
  useEffect(() => {
    getBanners()
  }, [])

  const getBanners = () => {
    getDocs(collection(db, 'banners')).then(res => {
      let tmp = []
      res.forEach(foo => {
        tmp.push({...foo.data(), id: foo.id})
      })
      setData(tmp)
    }).catch(err => {

    }).then(() => {
      setLoading(false)
    })
  }

  const getImageUrl = async (foo) => {
    const path = ref(storage, `banners/${foo}`)
    let _url = await getDownloadURL(path)
    return _url
  }

  const columns = [
    {
      title: 'Зураг',
      name: 'image',
      dataIndex: 'image',
      key: 'file',
      render: (text, row) => {
        return(
          <Image width={140} height={140} src={text} base="banners"/>
        )
      }
    },
    {
      title: 'Banner type',
      dataIndex: 'type',
      key: 'type',
      render: (text, item) => {
        return(
          <Link 
          to={`/editor/banner/${item.id}`}> 
          <span >{text}</span>
          </Link>
        )
      }
    },
    {
      title: 'Идэвхтэй эсэх',
      dataIndex: 'status',
      key: 'status',
      // width: 200,
      align: 'center',
      render: (text, item) => {
        return(
          <span className={twMerge('font-semibold', text == 1 ? 'text-green-500' : 'text-gray-500')}>
            {text == 1 ? 'Идэвхтэй' : 'Идэвхгүй'}
          </span>
        )
      }
    },
    {
      title: '',
      key: 'action',
      align: 'center',
      width: 120,
      render: (text, item) => (
        <span className='flex'>
          <Button danger size='small' onClick={(e) => handleRowDeleteClick(item, e)}>Устгах</Button>
        </span>
      ),
    },
  ];

  const handleRowDeleteClick = (data, e) => {
    e.stopPropagation();
    modal.confirm({
      title: "Анхаарна уу?",
      content: "Энэхүү мөр бичлэгийг устгах гэж байна",
      okText: "Устгах",
      cancelText: "Буцах",
      onOk() {
        return new Promise((resolve, reject) => {
          deleteDoc(doc(db, 'banners', data.id)).then(res => {
            message.success("Амжилттай устгалаа")
            deleteObject(ref(storage, `banners/${data.image}`))
            resolve()
          }).catch(err => {
            message.error("Устгахад алдаа гарлаа")
            reject()
          }).then(() => {
            getBanners()
          })
        }).catch(() => {});
      },
      onCancel() {},
    });
  };

  return (
    <>
      <div className='flex justify-between bg-white py-2 px-3 border-b'>
        <span>Banner List <span>({data.length})</span></span>
        <Link
         to={'/banner/add'}
         >
          <Button type='primary' size='small'>
            Нэмэх
          </Button>
        </Link>
      </div>
        <Table
          columns={columns}
          dataSource={data}
          rowKey={record => record.id}
          size='small'
          loading={loading}
          pagination={{
            defaultPageSize: 20
          }}
        >
      </Table>
      {contextHolder}
    </>
  );
}

export default Banner;
